import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

// Styling
import '../../../style/components/blog/articleTemplate.scss';

import BANNER_ICON_UP from "../../../resources/images/misc/plus_icon_up.png";
import WhatIsARoofingSquare from "../../../resources/images/blogs/roofing-square/01-featured-roofing-squares.png";
import Measure from "../../../resources/images/blogs/roofing-square/02-measure-calculate.png"
import Inspection from "../../../resources/images/blogs/roofing-square/03-inspection-roof-docs.png"

class RoofingSquare extends Component {

    constructor(props) {
        super(props);

        this.state = { };
    }



    render() {

        const search = window.location.search;
        const params = new URLSearchParams(search);
        const ref = params.get('ref') ? params.get('ref') : '0';

        return (
            <div className="blog-template-container">
                <div className="blog-template-banner">
                    <div className="blog-template-text-container">
                        <p className="banner-text-small"> Blog </p>
                        <p className="banner-text-large"> Learn More About Roofing From Roof-ER </p>
                        <Helmet>
                            <title>What is a Roofing Square? | Roof-ER</title>
                            <meta name="description" content="What is a roofing square and how is it used? Learn more about roofing squares from Roof-ER. The roofing experts in Fairfax County."/>
                        </Helmet>
                    </div>
                    <img className="banner-icon" src={BANNER_ICON_UP} alt="Banner Icon Up" />
                </div>
                <div className="blog-template-content-container">
                    <div className="blog-template-content" style={{ width: '100%'}}>
                        <div className="blog-item-container">
                            <div className="blog-item-info-container-full">
                                <a href="/blog"><button className="blog-home-button"><span className="arrow-left"> Back to Articles </span> </button></a>
                            </div>
                            <div className="blog-item-info-container-full">
                                <a href={`/blog?ref=${ref}&dir=prev`}><button  className="blog-previous-button"><span className="arrow-left"> Previous Article </span> </button></a>
                                <a href={`/blog?ref=${ref}&dir=next`}><button className="blog-next-button"><span className="arrow-right"> Next Article </span> </button></a>
                            </div>
                            <img className="long-form-image" src={WhatIsARoofingSquare} alt="Roofing Square" />
                            <div className="blog-item-info-container-full">
                                <h1>What Is a Roofing Square?</h1>
                                    <p>If you have recently hired a contractor or looking to get your roof repaired or replaced, you may have heard some industry people using terms and words like &ldquo;squares&rdquo; and &ldquo;shingles.&rdquo; If these terms leave you with some confusion, you&rsquo;ve found the right place.</p>
                                    <p>So what is a square in roofing? Put simply, a roofing square is equivalent to 100 square feet of the roof. <a href="/docs">Roofing professionals and contractors</a>&nbsp;use the term regularly to calculate materials and labor. We suggest understanding what the term means when hiring such people or spending money on your home.</p>
                                <h2>What Is a Roof Square Used For?</h2>
                                    <p>As noted above, contractors and other roofing professionals use the roof square to calculate how many roofing materials they will need and estimate how much labor repairing or replacing your roof will take. It's important to know what a roofing square is and how it's used if you are interested in construction or if you&rsquo;re a homeowner wanting to know more about how professionals calculate estimates for work on your roof. It's best if you have a basic understanding of a roofing square before you attempt to measure the size of your roof or begin any alterations on your home.</p>
                                    <p>A roofing square is a unit of measurement used to determine the size of your roof and is equivalent to 100 square feet. Professionals most often use the roofing square to calculate the cost of materials and labor.</p>
                                    <p>The roofing square is mostly used within the construction and roofing industries, but if you're a homeowner, we suggest you have a little knowledge about its use and how to determine your roof's size in roofing squares, especially when spending money on your home. However, it's best to leave the actual use of the tool to the professional as it can be tricky to measure accurately. If you calculate the measurements incorrectly and send this incorrect information to your contractor or roofer, you could waste money by ordering the incorrect amount of materials for the job.</p>
                                <h2>How to Measure a Roofing Square</h2>
                                    <p>Now that you know what a roofing square is, your next step is to look at how to measure it. This is the more challenging part of the task, but also the most important one. So how exactly do you measure a roofing square?</p>
                                    <p>Remember that a roofing square equals 100 square feet of the roof. To determine how many squares are on the roof, you need to calculate the total square footage and divide it by 100. The measurement does not change whether you are measuring for a square of roofing shingles or a square of underlayment, so stick to the same method.</p>
                                    <p>Here are the steps to measuring your roof and calculating how many squares it is:</p>
                                        <ol>
                                            <li><strong>Make sure you&rsquo;re secure:&nbsp;</strong>Ensure your ladder is in good working order and set it up in a secure, level spot. Double-check that there are no issues with your ladder or its placement that may result in you falling from the roof.</li>
                                            <li><strong>Measure more than once:</strong>&nbsp;To ensure you measure accurately, do the job more than once. Measure the length and width of your roof. For example, you may have a simple gable roof made of two rectangles, and the length and width of each rectangle are 30 feet and 20 feet.</li>
                                            <li><strong>Calculate the square footage:</strong>&nbsp;Once you have measured the length and width of each section of your roof, you can calculate how large your roof is in square feet. Multiply the width by the length for each section to calculate the square footage. The square footage of our example roof would be 30 x 20 = 600 square feet for each rectangle and 600 + 600 = 1,200 square feet for the total square footage.</li>
                                            <li><strong>Calculate the roofing square:</strong>&nbsp;To calculate the number of roofing squares from the square footage, all you have to do is divide your square footage by 100. The example roof would be 1,200 square feet &divide; 100 = 12 roofing squares.</li>
                                        </ol>
                            </div>
                            <img className="long-form-image" src={Measure} alt="Measure A Roofing Square" />
                            <div className="blog-item-info-container-full">
                                <h2>How Many Shingles Are in a Square?</h2>
                                    <p>The number of shingles in a square depends on the size of the shingles.</p>
                                    <p>Roof shingles are sold in bundles and by the square. One package of shingles is called a bundle. Most manufacturers pack them so three bundles equal 100 square feet or one roofing square. A bundle of shingles generally contains between 15 and 29 shingles, depending on the type and size of the shingles.</p>
                                    <p>How many shingles are in a bundle and how many bundles equal a square vary by brand. Many bundles will indicate a shingle minimum on the package, which means you're guaranteed at least that many shingles. Regardless of how many shingles are in a bundle, three bundles will still cover about one square or 100 square feet.</p>
                                    <p>For our example roof, you would need 12 x 3 = 36 bundles, which would be between 540 and 1,044 individual shingles.</p>
                                <h2>What Is the Cost per Square?</h2>
                                    <p>The cost per square depends on the types of materials you use. <a href="/services/roofreplacement">Standard asphalt shingle roof replacements</a>&nbsp;, including labor costs, generally fall between a price range of $400 to $550 per square, depending on your geographic location. Tiles and other materials tend to cost more than asphalt shingles.</p>
                                    <p>Other pricing factors to consider include the following:</p>
                                        <ul>
                                            <li><strong>The size of your roof:</strong>&nbsp;Of course, the larger your roof is, the more materials you will need and the longer it will take to reroof, so it will cost more than a smaller roof.</li>
                                            <li><strong>The pitch of your roof:</strong>&nbsp;A very steep roof can be more difficult or dangerous to access and can make installing a new roof more challenging.</li>
                                            <li><strong>If the old roof needs to be removed:</strong>&nbsp;Sometimes, new shingles can be placed over old shingles. If your roofers need to remove the old roof before installing the new roof, it will be more expensive.&nbsp;</li>
                                            <li><strong>If you have any remaining warranty on your roof:</strong>&nbsp;If your roof is still under warranty, the warranty may cover some of the materials or labor.</li>
                                            <li><strong>The time of year you need work done:</strong>&nbsp;It may be less expensive to have your roof repaired or replaced during the off-season.</li>
                                        </ul>
                                <h2>Contact Roof-ER for Your Roof Replacement</h2>
                                    <p>Though this information gives you the necessary tools to help you measure your roof and get started on the process of a roof replacement, it's best to leave the job for a qualified professional. Roof-ER offers the&nbsp;<a href="/areas/fairfax">best roofing services in Fairfax County</a>. Contact us to&nbsp;<a href="/inspection">schedule your free inspection</a>&nbsp;today!</p>
                            </div>
                            <a href="/inspection"><img className="long-form-image-clickable" src={Inspection} alt="Inspection" /></a>
{/*                            <script type="application/ld+json">*/}
{/*{*/}
{/*  "@context": "https://schema.org",*/}
{/*  "@type": "FAQPage",*/}
{/*  "mainEntity": [{*/}
{/*    "@type": "Question",*/}
{/*    "name": "What Is a Roofing Square?",*/}
{/*    "acceptedAnswer": {*/}
{/*      "@type": "Answer",*/}
{/*      "text": "A roofing square is equivalent to 100 square feet of the roof. Roofing professionals and contractors use the term regularly to calculate materials and labor. We suggest understanding what the term means when hiring such people or spending money on your home."*/}
{/*    }*/}
{/*  },{*/}
{/*    "@type": "Question",*/}
{/*    "name": "What Is a Roof Square Used For?",*/}
{/*    "acceptedAnswer": {*/}
{/*      "@type": "Answer",*/}
{/*      "text": "A roofing square is a unit of measurement used to determine the size of your roof and is equivalent to 100 square feet. Professionals most often use the roofing square to calculate the cost of materials and labor."*/}
{/*    }*/}
{/*  },{*/}
{/*    "@type": "Question",*/}
{/*    "name": "How to Measure a Roofing Square",*/}
{/*    "acceptedAnswer": {*/}
{/*      "@type": "Answer",*/}
{/*      "text": "To determine how many squares are on the roof, you need to calculate the total square footage and divide it by 100. The measurement does not change whether you are measuring for a square of roofing shingles or a square of underlayment, so stick to the same method."*/}
{/*    }*/}
{/*  },{*/}
{/*    "@type": "Question",*/}
{/*    "name": "How Many Shingles Are in a Square?",*/}
{/*    "acceptedAnswer": {*/}
{/*      "@type": "Answer",*/}
{/*      "text": "The number of shingles in a square depends on the size of the shingles. Roof shingles are sold in bundles and by the square. One package of shingles is called a bundle. Most manufacturers pack them so three bundles equal 100 square feet or one roofing square. A bundle of shingles generally contains between 15 and 29 shingles, depending on the type and size of the shingles."*/}
{/*    }*/}
{/*  },{*/}
{/*    "@type": "Question",*/}
{/*    "name": "What Is the Cost per Square?",*/}
{/*    "acceptedAnswer": {*/}
{/*      "@type": "Answer",*/}
{/*      "text": "The cost per square depends on the types of materials you use. Standard asphalt shingle roof replacements, including labor costs, generally fall between a price range of $400 to $550 per square, depending on your geographic location. Tiles and other materials tend to cost more than asphalt shingles."*/}
{/*    }*/}
{/*  }]*/}
{/*}*/}
{/*</script>*/}

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default RoofingSquare;