import React, { Component } from 'react';
import FreeQuoteBanner from "./custom/freeQuoteBanner";
import {Helmet} from "react-helmet";

// Styling
import '../style/components/work.scss';
import '../style/components/modal.css'


// Images
import INSPECT from '../resources/images/ourWork/img_1inspect.png';
import DIAGNOSE from '../resources/images/ourWork/img_2diagnose.png';
import PRESCRIBE from '../resources/images/ourWork/img_3prescribe.png';
import OPERATE from '../resources/images/ourWork/img_4operate.png';

import BANNER_ICON_UP from '../resources/images/misc/plus_icon_up.png';



import WorkStepItem from "./custom/workStepItem";

const STEPS = [
    { name: 'Inspect', icon: INSPECT, info: ['ROOF', <span className="logo-text" key="1">ER</span>,
            ' will perform a FREE inspection of your roof, siding, and gutters to find evidence of wind, hail, or other damage.']},
    { name: 'Diagnose', icon: DIAGNOSE, info: ['ROOF', <span className="logo-text" key="2">ER</span>, ' and your Roof Doc will properly assess all findings to determine whether your exterior systems are storm-damaged, old, or perfectly fine.']},
    { name: 'Prescribe', icon: PRESCRIBE, info: ['Your Roof Doc will provide you with an honest recommendation based on their findings.']},
    { name: 'Operate', icon: OPERATE, info: ['If your roof, siding, or gutters are found to have an ailment, the last step is to review project details with your Roof Doc and let ROOF',  <span className="logo-text" key="4">ER</span>, '\'s install team take care of the rest. ']}
];

const INSPECT_STEPS = [
    'You will be notified once we arrive (no direct contact required)',
    'We will take all necessary safety precautions prior to performing the inspection',
    'Next, we\'ll complete our free inspection using our custom Field - App to determine the condition of your exterior systems'
];

const DIAGNOSE_STEPS = [

    'Our Field-app goes through a step-by-step checklist to ensure we gather all the information needed to properly assess your roof, siding, & gutter systems ',
    'The items we look for include wind and hail damage, weathering effects, faulty installation methods, etc.',
    'Upon synthesizing our findings, we will determine our recommended course of action: a repair or replacement, an insurance claim for storm damage, or a simple sign off that your exterior is in good shape for a few more years!'
];

const PRESCRIBE_STEPS = [
    ['Property insurance claim: if ROOF', <span className="logo-text" key="2">ER</span>, ' finds storm-related damage to your roof, siding, or gutters, we may recommend filing a claim with your insurance company. You can learn more about this process by visiting our ', <a href="/faqs"><span className="roofDocsRed">FAQs page</span> </a>],
    'Repair/replacement: often times, homeowners ask us to inspect their property because they\'re aware of an issue already. In these cases, our Roof Docs are experts at deciding exactly what needs done to ensure those problems are addressed',
    'Good/great condition: even if your roof/siding/gutters were installed quite a few years ago, there\'s a chance they have maintained their useful life and will remain serviceable for a few more years. We pride ourselves on providing honest recommendations to our homeowners - we won\'t sell you what you don\'t need!'
];

const OPERATE_STEPS = [
    'If work is required, we will do our best to educate you on all of your options, focusing on how to provide you with the most value',
    'Our Roof Docs can recommend colors & products based on your style of home and your preferences. The best part? Our pricing is 100% haggle-free',
    'Once the project details have been completed, our production team takes over and our factory-certified installers deliver a professional remodeling project!'
];


class OurProcess extends Component {

    constructor(props) {
        super(props);

        this.state = { showMorePhotos: false}
    }

    render() {



        return (
            <div className="our-work-container">
                <div className="work-banner">
                    <div className="work-banner-text-container">
                        <p className="banner-text-small"> OUR PROCESS </p>
                        <p className="banner-text-large"> Simplified for Exceptional Service </p>
                   <Helmet>
                       <title>Our Simplified Roofing Process | Roof-ER</title>
                       <meta name="description" content="Roof-ER follows a streamlined and efficient process to get the job done right. Contact us for roof repair and replacement services in Fairfax County."/>
                   </Helmet>
                    </div>
                    <img className="banner-icon" src={BANNER_ICON_UP} alt="Banner Up"/>
                </div>

                <div className="work-process-container">
                    <div className="work-process-banner">
                        {/*<img className="banner-icon" src={BANNER_ICON_DOWN} alt="Banner Down"/>*/}
                        <p className="main-text"> OUR FOUR STEP PROCESS </p>
                        <p className="small-text"> A standard approach to every job ensures a quality restoration </p>
                    </div>
                    <div className="work-process-content">

                        {/* INSPECT */}
                        <div className="work-item-container">
                            <div className="work-item-icon-container">
                                <img src={STEPS[0].icon} alt="Step" />
                            </div>
                            <div className="work-item-info-container">
                                <p className="step-header"> 1. { STEPS[0].name } </p>
                                <p className="step-info"> { STEPS[0].info } </p>
                                { INSPECT_STEPS.map( (step, index) => (
                                    <WorkStepItem key={index} text={ step } />
                                ))}
                            </div>
                        </div>

                        {/* DIAGNOSE */}
                        <div className="work-item-container">
                            <div className="work-item-icon-container">
                                <img src={STEPS[1].icon} alt="Step" />
                            </div>
                            <div className="work-item-info-container">
                                <p className="step-header"> 2. { STEPS[1].name } </p>
                                <p className="step-info"> { STEPS[1].info } </p>
                                { DIAGNOSE_STEPS.map( (step, index) => (
                                    <WorkStepItem key={index} text={ step } />
                                ))}
                            </div>
                        </div>

                        {/* PRESCRIBE */}
                        <div className="work-item-container">
                            <div className="work-item-icon-container">
                                <img src={STEPS[2].icon} alt="Step" />
                            </div>
                            <div className="work-item-info-container">
                                <p className="step-header"> 3. { STEPS[2].name } </p>
                                <p className="step-info"> { STEPS[2].info } </p>
                                { PRESCRIBE_STEPS.map( (step, index) => (
                                    <WorkStepItem key={index} text={ step } />
                                ))}
                            </div>
                        </div>

                        {/* OPERATE */}
                        <div className="work-item-container">
                            <div className="work-item-icon-container">
                                <img src={STEPS[3].icon} alt="Step" />
                            </div>
                            <div className="work-item-info-container">
                                <p className="step-header"> 4. { STEPS[3].name } </p>
                                <p className="step-info"> { STEPS[3].info } </p>
                                { OPERATE_STEPS.map( (step, index) => (
                                    <WorkStepItem key={index} text={ step } />
                                ))}
                            </div>
                        </div>


                        {/* OLD CODE TO BRING BACK POSSIBLE */}
                        {/*{ STEPS.map( (step, index) => (*/}
                        {/*    <div key={index} className="work-item-container">*/}
                        {/*        <div className="work-item-icon-container">*/}
                        {/*            <img src={step.icon} alt="Step" />*/}
                        {/*        </div>*/}
                        {/*        <div className="work-item-info-container">*/}
                        {/*            <p className="step-header"> { index + 1}. { step.name } </p>*/}
                        {/*            <p className="step-info"> { step.info } </p>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*))}*/}
                    </div>

                </div>

                {/*<Products />*/}
                <div className="gray-banner" />
                <FreeQuoteBanner />
            </div>
        );
    }

}

export default OurProcess;
