import React, { Component } from 'react';


// STYLING
import '../../style/components/custom/selectInput.scss';


const ROOF_OPTIONS = [
    '1', '2', '3', '4', '5', '6', '7', '8', '9', '10+', 'Unknown'
];
const ROOF_REPLACEMENT_OPTIONS = [
    'Repair', 'Full Replacement'
];
const SIDING_OPTIONS = [
    'Vinyl', 'Metal', 'Unsure/Other'
];
const HOUSE_TYPE = [
    'Single-family',
    'Townhouse'
]

class DropDownInput extends Component {


    constructor(props) {
        super(props);

        this.state = {value: null, placeholder: ''}
    }


    render() {

        let title = '';
        let options = [];

        if(this.props.trade === 'roof'){
            title = 'Age of Roof? (Optional)';
            options = ROOF_OPTIONS
        } else if(this.props.trade === 'roofReplacement'){
            title = 'Roof Replacement? (Optional)';
            options = ROOF_REPLACEMENT_OPTIONS;
        } else if(this.props.trade === 'houseType') {
            title = 'House Type (Optional)';
            options = HOUSE_TYPE;
        } else {
            title = 'Siding Type (Optional)';
            options = SIDING_OPTIONS;
        }


        return (
            <div className="select-input-container" hidden={this.props.hidden}>
                <div className="drop-down-select-info">
                    <p> { title } </p>
                </div>
                <select onChange={this.valueChanged}>
                    <option key={0} value={null}> {'Select...'} </option>
                    {options.map((opt, index) => (
                        <option key={index} value={opt}> {opt} </option>
                    ))}
                </select>
            </div>
        );
    }

    valueChanged = e => {
        //
        // let placeholder = _.filter(OPTIONS, opt => {
        //     return opt.option === e.target.value
        // })[0]['placeholder'];
        //
        //
        // this.setState({ value: e.target.value, placeholder, referral: null });
    };

}

export default DropDownInput;