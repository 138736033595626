import React, { Component } from 'react';
import {Helmet} from "react-helmet";

import FreeQuote from "./custom/freeQuoteBanner";

// Styling
import '../style/components/meetTheDocs.scss';

// Images

import AhmedM from '../resources/images/employees/AhmedM.png';

import AmberCouch from '../resources/images/employees/AmberCouch.png';
import AndreM from '../resources/images/employees/AndreM.png'
import CarlosDavila from '../resources/images/employees/CarlosDavila.png';
import ChrisF from '../resources/images/employees/ChrisF.png';
import ChrisRamirez from '../resources/images/employees/ChrisRamirez.png';
import ChrisT from '../resources/images/employees/ChrisT.png';
import ChristianM from '../resources/images/employees/ChristianM.png';
import CorbinH from '../resources/images/employees/CorbinH.png';
import DannyT from '../resources/images/employees/DannyT.png';
import DausonT from '../resources/images/employees/DausonT.png';

import DontelB from '../resources/images/employees/DontelB.png';
import DylanG from '../resources/images/employees/DylanG.png';
import FordBarsi from '../resources/images/employees/FordBarsi.png';
import FreddyZ from '../resources/images/employees/FreddyZ.png';
import GarrettW from '../resources/images/employees/GarrettW.png';
import GregCampbell from '../resources/images/employees/GregCampbell.png';
import IsmaelCoreas from '../resources/images/employees/IsmaelCoreas.png';

import JasonB from '../resources/images/employees/JasonB.png';

import JustinW from '../resources/images/employees/JustinW.png';
import KyannHolman from '../resources/images/employees/KyannHolman.png';
import KyleM from '../resources/images/employees/KyleM.png'

import LuisEsteves from '../resources/images/employees/LuisEsteves.png';
import MichaelR from '../resources/images/employees/MichaelR.png';
import MichaelS from '../resources/images/employees/MichaelS.png';
import MattRay from '../resources/images/employees/MattRay.png';
import Mike from '../resources/images/employees/MikeHarvey.png';
import MikeRafter from '../resources/images/employees/MikeRafter.png';
import MikeT from '../resources/images/employees/MikeT.png';

import Oliver from '../resources/images/employees/OliverBrown.png';
import PatrickRobertson from '../resources/images/employees/PatrickRobertson.png';
import PeytonF from '../resources/images/employees/PeytonF.png';
import ReeseS from '../resources/images/employees/ReeseS.png';
import RichieR from '../resources/images/employees/RichieR.png';

import TroyS from '../resources/images/employees/TroyS.png';
import WillC from '../resources/images/employees/WillC.png';
import WillS from '../resources/images/employees/WillS.png';
import ZacNewdick from '../resources/images/employees/ZacNewdick.png';

// import DennisK from '../resources/images/employees/DennisK.png';
// import DomK from '../resources/images/employees/DomK.png';
// import AdelS from '../resources/images/employees/AdelS.png';
// import AliB from '../resources/images/employees/AliB.png'
// import JavierK from '../resources/images/employees/JavierK.png';
// import JoeyH from '../resources/images/employees/JoeyH.png';
// import JohnL from '../resources/images/employees/JohnL.png';
// import JonJ from '../resources/images/employees/JonJ.png';
// import LucaF from '../resources/images/employees/LucaF.png';
// import MoeI from '../resources/images/employees/MoeI.png';
// import NicoW from '../resources/images/employees/NicoW.png';
// import SirajeJ from '../resources/images/employees/SirajeJ.png';
// import StevenW from '../resources/images/employees/StevenW.png';
// import JakeB from '../resources/images/employees/JakeB.png';
// import JalynM from '../resources/images/employees/JalynM.png';
// import MaryLombardi from '../resources/images/employees/MaryLombardi.png';
// import HunterArnold from '../resources/images/employees/HunterArnold.png';
// import AramGhanbari from  '../resources/images/employees/AramGhanbari.png';
// import ZachMyers from '../resources/images/employees/ZachMyers.png';
// import PatrickOHara from '../resources/images/employees/PatrickO\'Hara.png';
// import HazemAtallah from '../resources/images/employees/HazemAtallah.png'
// import AryanGhaemi from '../resources/images/employees/AryanGhaemi.png'
// import KalebKuschel from '../resources/images/employees/KalebKuschel.png';
// import AlexCampos from '../resources/images/employees/AlexCampos.png';
// import RyanV from '../resources/images/employees/RyanV.png';
// import ChrisDavis from '../resources/images/employees/ChrisDavis.png';
// import Rees from '../resources/images/employees/ReesNalley.png';
// import AustinStephens from '../resources/images/employees/AustinStephens.png';
// import JoelH from '../resources/images/employees/JoelH.png';
// import JohnC from '../resources/images/employees/JohnC.png';
// import EricM from '../resources/images/employees/EricM.png';
// import AselT from '../resources/images/employees/AselT.png';
// import TJSpencer from '../resources/images/employees/TJSpencer.png';
// import FrancisKang from '../resources/images/employees/FrancisKang.png';


// import RyanHall from '../resources/images/employees/RyanHall.png';
// import NoeAleman from '../resources/images/employees/NoeAleman.png';
// import AllenMckelton from '../resources/images/employees/AllenMckelton.png';
// import KyleGott from '../resources/images/employees/KyleGott.png';
// import RyanFerguson from '../resources/images/employees/RyanFerguson.png';
// import TimBlackwell from '../resources/images/employees/TimBlackwell.png';
// import Matt from '../resources/images/employees/MattFoster.png';
// import ChaseStrait from '../resources/images/employees/ChaseStrait.png';
// import CormacForan from '../resources/images/employees/CormacForan.png';
// import DaveLaGarde from '../resources/images/employees/DaveLaGarde.png';
// import JoeMac from '../resources/images/employees/JoeMac.png';
// import DanielKeene from '../resources/images/employees/DanielKeene.png';
// import JamesMcCarty from '../resources/images/employees/JamesMcCarty.png';
// import MikeMac from '../resources/images/employees/MikeMac.png';
// import JimmySnyder from  '../resources/images/employees/JimmySnyder.png';
// import EthanDsouza from '../resources/images/employees/EthanDsouza.png';
// import BryamGodoy from '../resources/images/employees/BryamGodoy.png';
// import JesseBrouhard from '../resources/images/employees/JesseBrouhard.png';
// import DaleArbaugh from '../resources/images/employees/DaleArbaugh.png';

import BANNER_ICON_UP from "../resources/images/misc/plus_icon_up.png";
import EXPAND_ICON from "../resources/images/misc/icon_plusred.png";
import COLLAPSE_ICON from "../resources/images/misc/icon_closered.png";
import moment from "moment";
import axios from "axios";

const MIKE_BIO = 'Michael’s very first job was roofing at age 15. From there, he went on to receive a B.A. in International Affairs and an MBA in finance, only to end up right back ' +
    'where it all started. He’s had almost enough schooling to be an M.D., but opted for his R.D. (Roof Doctorate) instead. Michael believes if you approach every endeavor with integrity, ' +
    'are uncompromising on quality, and remain committed to simplicity, success will abound. (Michael is also a washed-up former college athlete. If you have a basketball hoop, he’ll be ' +
    'glad to show you)';
const OLIVER_BIO = 'Oliver once spent a summer loading shingles onto roofs and is very happy to have moved up the ladder in the roofing industry. Since that summer, he has earned his B.S. in ' +
    'Business Marketing & MBA from Mount St. Mary’s University in 2013 & 2015, respectively. Oliver is a passionate sports fan, dog owner, and food connoisseur who believes anything is ' +
    'possible with a gritty, positive mindset. (Oliver is a less "washed-up” college basketball player than Michael and would love to prove that in anyone\'s driveway.)';

// const REES_BIO = 'Rees was introduced to the home remodeling industry just out of college when he worked as a site supervisor for a family member’s construction company. While there, he ' +
//     'discovered a deep satisfaction for improving the quality of people’s homes and decided to make it his career. Before his work there, Rees graduated from Mount St. Mary’s University ' +
//     'where he achieved a B.A. in History and played four years of rugby as a Mountaineer. When Rees isn’t on the job he’s in the gym, on a boat, or on the rugby pitch.';
// const CHRIS_BIO = 'Chris is not the ideal candidate to inspect a roof, which is why he\'s kept behind a desk.  Chris has a bachelor\'s degree in Computer Science, and is in charge of software development with The Roof ' +
//     'Docs.  He enjoys streamlining business processes through software, which helps the Docs when they\'re out in the field.  He\'s slowly learning the industry, who knows, maybe one day he\'ll be qualified enough ' +
//     'to help with your inspection';
const GREG_BIO = 'Greg went to Shepherd University before moving to California in 1994 where he entered the roofing industry by delivering material to roof tops all over the SoCal area. Greg came back to ' +
    'the DMV area, played Semi-Pro football, and has worked for roofing suppliers and installers for the past 20+ years. As our production manager Greg has a strong belief that the customer should receive ' +
    'comprehensive service on each and every job. Greg and his wife Jenn have 2 boys in elementary school and enjoy watching them play sports.';

// const AUSTIN_BIO = 'Austin has been interested in building since a young age, which led him to study Engineering Technology at ' +
//     'Lincoln Tech in Columbia, MD.  He applied what he learned there to help with his father’s building company in southern ' +
//     'Maryland until he decided to venture into home remodeling. He’s an expert in all things residential exterior and he’d love to ' +
//     'make a deal with you in the driveway or on the golf course.';

const MATT_RAY_BIO = 'Through the years Matt has always found himself in a customer service driven position. From various retail positions in High School, onto managing client focused projects ' +
    'for exterior remodelers over the past 10 years after college. Matt has always liked being in the DC metro region, and in keeping with that, attended George Mason University where he received his ' +
    'bachelor’s degree in Criminal Justice as well as played 3 seasons with GMU’s ice hockey team. Matt now spends his free time chasing after his two and a half kids as well as jumping into any ' +
    'hockey game he can gets his hands on. To date, none of the other roof docs have had the courage to lace up some skates.'
const FORD_BIO = 'Ford was born and raised in Tampa, Florida where he developed an unhealthy obsession with the Tampa Bay Buccaneers ' +
    'and life on the water. He graduated from the University of Florida in 2009 with a degree in Sociology, but his passion for food ' +
    'ultimately led him to New York City, where he became an executive chef. His food journey took him to Washington, DC to lead ' +
    'the opening of the largest restaurant in the city. He cut his teeth in the wine business next, selling to some of the best hotels ' +
    'and restaurants in our nation’s capital. Through his many journeys he’s taken with him a sincere passion for hospitality and a ' +
    'genuine desire to help others. He is a self-proclaimed sports junkie, DIY home renovator, and the humble father of a three year ' +
    'old princess. ';
const PATRICK_BIO = 'Patrick is originally from England and recently joined the team after living in Boston, MA for the past few years. ' +
    'He spent some summers working for his friend’s family roofing company in the UK before heading to the US as a soccer recruiter ' +
    'after graduating from the University of Essex. Despite his passion for Soccer, his playing career didn’t quite go as planned which ' +
    'is why you might see him in a neighbourhood near you and not on TV on Saturday mornings playing for Manchester United.';
// const MARY_BIO = 'Mary grew up in Colorado and has been involved in a couple different business jobs. She has spent time as a project coordinator ' +
//     'intern and graduate assistant to the professors and Dean of the business school at Mount St. Mary’s in MD. She has earned her B.S. in Business ' +
//     'Administration & MBA from Mount St. Mary’s University in 2019 & 2020, respectively. She is not the best person for the job when it comes to inspecting ' +
//     'and selling roofs, which is why we have her behind the scenes as our executive assistant. She hopes to leave an impact here at Roof-ER as she is the only female on our team. ' +
//     '(Mary was also a former college athlete. She was a swimmer all four years at the Mount and is quite literally “washed-up” as she is now a fish out of water). ';
const LUIS_ESTEVES_BIO = 'Born and raised in Rotorua, New Zealand, Luis moved to the States to play college soccer at St. John\'s University where he graduated with a B.S. in International Business and ' +
    'Management Degree. His passion for soccer has transitioned to the professional world. Where previously he assisted medical doctors with new surgical devices, he now takes pride in being a doc himself - ' +
    'Roof Doc that is. In his free time, he enjoys the outdoors, either hiking mountains or playing two touch at the beach with family and friends';
const ISMAEL_COREAS_BIO = 'Ismael has been in the construction industry for the past 20 years. He started as a punch-out specialist working on new residential homes. He soon climbed up the ladder and ' +
    'became a rough frame carpenter. During all his time spent in the field, he’s acquired many different skills - from roofing installation and repairs to windows and doors - and has developed a penchant ' +
    'for high-quality work and attention to detail. You’ll meet him at the end of your project, making sure the install has been performed with the quality and care he’s practiced over the last 20 years';
// const HUNTER_ARNOLD_BIO = 'Hunter is 25 and grew up in Gambrills Maryland, and has lived there his whole life. He spent the last couple years in the restaurant industry selling fajitas, ' +
//     'but has now decided to pursue a career as a Roof Doc. He is a huge hockey and gym guy and that’s usually how he spends his free time when he isn\'t inspecting roofs.';
// const ARAM_GHANBARI_BIO = 'Aram is a native Virginian, who graduated from George Mason University where he achieved a B.S. Finance. He now has his PhD in roofing and is a certified Roof Doc. In his free time he is a ' +
//     'regular at CrossFit gym and loves going on outdoor adventures with his gorgeous husky!';
// const ZACH_MYERS_BIO = 'Zach was introduced into the remodeling industry in North Carolina and always felt passionate about helping people. He was in the fitness industry ' +
//     'for quite some time as a Vice President but wanted to pursue his passion in the roofing industry. While not on a roof you can find him in the gym or at the beach!';
const MIKE_RAFTER_BIO = 'While growing up in Massachusetts, Mike developed a passion for real estate construction and development. From a young age, Mike’s dad would bring him to his ' +
    'residential development projects to explore the new houses while under construction. After graduating from The University of Tampa, Mike spent eight years in commercial real estate ' +
    'brokerage, much of his time focused on selling residential development sites in NYC. Now living in the DMV area, Mike is excited to guide homeowners though major projects they undertake ' +
    'while committing to delivering exceptional quality. When not on the job site, Mike enjoys staying active and traveling whenever he gets the chance.';
const CARLOS_DAVILA_BIO = 'Carlos is a Northern VA native. Played soccer at North Carolina Wesleyan College. Loves all sports but especially Soccer and Mixed Martial ' +
    'Arts which makes Carlos A KO when it comes to sales'
// const PATRICK_OHARA_BIO = 'Patirck went to West Virginia University.  He has always been interested in restoration contract work. When he is not on a roof, he enjoys fishing and hanging out with friends and family'
const ZAC_NEWDICK_BIO = 'Zac is from Rotorua, New Zealand. He recently graduated from Missouri State University in the fall of 21’ with an International Business Degree ' +
    'while playing Division one soccer. He is excited to be out of the Midwest and into a more pleasant area of the country. Now, Zac prides himself on giving the best customer ' +
    'service he can to homeowners and going above and beyond their expectations. On most weekends you’ll see Zac on the golf course or tuning into a bit of Soccer or Rugby. '

// const HAZEM_ATALLAH_BIO = 'Hazem has a passionate dedication to helping people. Which is what motivated him to pursue the roofing industry. ' +
//     'While working as a Personal Training Director in a Fitness Franchise for a few years, you would think he would be tired of the gym, ' +
//     'right? Wrong, you will see him there on his off time or playing football. He loves to help others, it  is what drives him! ' +
//     'Remember “ if no one has em’ , He Haz em’'
// const ARYAN_GHAEMI_BIO = 'Aryan is a recent James Madison University graduate. He has always had a passion for helping people in any way ' +
//     'he can. In his free time you can catch him at the gym or playing some basketball.'
const CHRIS_RAMIREZ_BIO = 'Chris is  originally from California and moved to Maryland when he was younger. He has been here ever since. ' +
    'He was recently introduced to the remodeling industry this year. Before Roof-ER he worked as a general manager at LA fitness and learned ' +
    'a lot about customer service and the responsibilities it takes to be a great leader. RoofER has become such a great opportunity for him, ' +
    'and he wouldn’t change a thing! Being an extroverted person he truly enjoys helping homeowners restore the life and quality of their ' +
    'roofs. Meeting and making new connections is what makes this job enjoyable.'

// const KALEB_KUSCHEL_BIO = 'Kaleb came to the roofing industry through a chance encounter with one of our very own Roof Docs himself. ' +
//     'He found the professionalism of our team so inspiring that he felt he had to be on it! In his free time you can find Kaleb at ' +
//     'the gym or with his family.'
// const ALEX_CAMPOS_BIO = 'Alex was very successful in the fitness industry before joining the Roof-ER team. He also grew up in the dmv , ' +
//     'mainly D.C, area so he knows the area well. Before gaining a passion for roofing, he worked in the construction field where he did ' +
//     'some carpentry as well as painting work.'
// const TJ_SPENCER_BIO = 'TJ is a Virginina native who was born in Charlottesville Virginia before moving to Northern Virginia for school. He continued ' +
//     'to work a variety of jobs throughout college from water mitigation, to junk removal, and even as a camp counselor. After graduating from George ' +
//     'Mason University in 2020 with a B.S. in Economics he decided he had enough manual labor and now works from the comfort of a standing desk. When ' +
//     'he is not supplementing an insurance claim you can usually find TJ at the gym, playing chess online, or training for his next Ironman. '
const KYANN_HOLMAN_BIO = 'Kyann was born in Bremerton, Washington but grew up in several states across the U.S. Her various moves, she views them as ' +
    'adventures, provided her a varied and unique upbringing which translates to her view of the world and its challenges today. Following her dreams to help ' +
    'create a better tomorrow, she graduated from the University of Mary Washington in Fredericksburg, VA in 2021 with her bachelors in Historic Preservation. ' +
    'During her time learning what it means to preserve the past while looking towards a more sustainable future, she found her passion for architecture, ' +
    'specifically sustainable architecture and adaptive reuse. Currently, she lives with her fiance, their cuddly cat and adorable pair of rats in Lorton, VA. ' +
    'She\'s an avid reader, loves being in nature, and enjoys a passionate discussion about someone\'s latest interests.'
// const FRANCIS_KANG_BIO = 'Francis has built a reputation of providing top quality customer service in the home remodeling industry for over ten years. He is best ' +
//     'known for maintaining long term relationships with his clients at a point they have become more of a friendship. When he\'s not in meetings discussing ' +
//     'nails and shingles, you can sometimes find him either at a local coffee shop reading a book or eating Korean barbecue with his friends.'
const AMBER_COUCH_BIO = 'Amber was born and raised in Alexandria VA. She graduated from Shenandoah University in 2017 with a Bachelors in Exercise Science ' +
    'and a Business minor. Amber also played college soccer there for 3 years but has since turned into a dedicated crossfit athlete  Amber has had many' +
    ' career paths before finding a passion for helping others through supplementing roof claims.  Amber has worked in the service industry since age 15. ' +
    'She has been a physical therapy technician as well as a licensed sports massage therapist. In Ambers free time she likes to spend time with friends ' +
    'and family and also plays in a local dart league.'

// const KYLE_GOTT_BIO = 'Kyle is Maryland born but primarily “Wild and Wonderful West Virginia” raised thus sparking the interest and appreciation for more hands-on work at an ' +
//     'early age. Even though he holds his B.S. in Computer & Information Tech from Marshall University, Kyle soon realized post graduation a job behind the desk ' +
//     'wasn’t for him. Since graduating he spent many years helping others in the fitness industry but was later introduced to the roofing world where he can continue ' +
//     'to help others in addition to rekindling those WV roots.'
// const RYAN_HALL_BIO = 'Growing up in Florida and Virginia, Ryan was first introduced to the remodeling business thanks to his Uncle, ' +
//     'a freemason. He always has a smile on his face and is passionate about helping people and going the extra mile. He enlisted in the ' +
//     'United States Coast Guard out of high school and since then, Ryan has also spent time in the fitness industry as well. When not on ' +
//     'a roof, you can catch him at the gym, watching his favorite sports teams, or spending quality time with family. '
// const NOE_ALEMAN_BIO = 'Born and raised in Virginia, Noe was first introduced to the remodeling business thanks to his father. He is very ' +
//     'passionate about helping others and it keeps a smile on his face.. Noe served 4 years of active service in the Marine Corps and was ' +
//     'also in the fitness industry. When not on a roof, you can catch him at the gym, Starbucks, or spending quality time with family.'
// const ALLEN_MCKELTON_BIO = 'Allen is a guy who loves sports and to travel, so that is what you\'ll find him doing in his free ' +
//     'time! He is here to help people the best he can, and is thrilled to be apart of the Roof-ER team.'
// const JOE_MAC_BIO = 'Joe grew up in Annapolis MD and moved to Buffalo where he became a die-hard Bills fan. ' +
//     'While still an active member of the Bills Mafia, he has since moved back to the Davidsonville area. He ' +
//     'has 3 years of experience within the home remodeling industry and recently graduated from Salisbury ' +
//     'University with a business management degree, where he also captained the men\'s rugby team. His ' +
//     'favorite part of the job is interacting face-to-face with clients and knowing he has not just met, ' +
//     'but exceeded their expectations. Joe likes to stay active and depending on the season, loves to ' +
//     'lace up the skates, cleats, and boxing gloves.';
//
// const DANIEL_KEENE_BIO = 'Dan worked as a financial analyst for two years after graduating with an economics degree in 2018. Back in college, he was part ' +
//     'of the founding team for a startup company that helped small businesses with disposing of their technology responsibly. During that time, he developed ' +
//     'a passion for helping people which has only increased with his experience. Dan is a big believer in serving his community with a relentless positive ' +
//     'attitude that improves the quality of life for everyone around him. He played rugby for 8years through schooling and is currently retired, but has plans ' +
//     'to put the boots back on again at some point. But for now, he is pursuing his PHD in roofing and increasing the value of your home.';
// const JAMES_MCCARTY_BIO = 'James was born and raised in Northern Virginia and went to West Virginia University for college where he earned his B.S. in Sport & Exercise Psychology. James has had past ' +
//     'jobs in sheet metal fabrication and healthcare, as he was once considering med school. He opted for a life of diagnosing and treating roofs as opposed to people as they require less anesthesia. He ' +
//     'enjoys cooking for his friends and family in his free time and has an unhealthy obsession with watching random youtube videos at 3 am.';
// const MIKE_MAC_BIO = 'Mike grew up in the Annapolis area and attended West Virginia University where he played club hockey. He graduated in 2020 with a degree in energy management and a minor ' +
//     'is business administration. In his free time he enjoys playing sports, hunting/fishing, hanging with friends and being outdoors. He is excited to be part of the roof docs and can’t ' +
//     'wait to see what the future holds!';

// const DAVE_LAGARDE_BIO = 'Born and raised in Northern Virginia, David’s background in construction and home improvement runs the gamut - From civil engineering and landscape design, to exterior ' +
//     'remodeling.  He approaches every project with a comprehensive vision and creative bent of mind.  The guy even has a BA in Film and Television Production from George Mason University!  He simply ' +
//     'loves building things.  Whether it’s his daughter’s appreciation for nature, a healthy physique in the gym, or your home’s aesthetic, he can promise that he gives every endeavor 110%';
// const JIMMY_SNYDER_BIO = 'Jimmy was born and raised in the DMV area. He is half Lebanese and half American. He grew up in Clifton, VA and then moved to Fort Washington, MD so to say the least he is ' +
//     'no stranger to diversity. Jimmy has a passion for racing and cars, and was a Porsche Technician for 5 years. Jimmy has a strong belief in his faith, family, treating people with respect ' +
//     'and being honest and true. His people skills go above and beyond and he is excited to be a Roof Doc!';
// const ETHAN_DSOUZA_BIO = 'Ethan started his career as an engineer and management consultant working for fortune 500 companies. After nearly a decade, he changed focus to pursuing family ' +
//     'life and work focused on improving the lives of his neighbors in a more meaningful and tangible way. His career passion is now helping homeowners improve the value and ' +
//     'aesthetics of their homes through the Roof-ER 4 step process. He loves working with elderly homeowners and immigrants who struggle to navigate home repairs by themselves.';

// const BRYAM_GODOY_BIO = 'Bryam has been in the construction industry for 8 years and has always had a knack for fixing things. Whether it\'s his own projects around the house or doing repair work for a customer, ' +
//     'he enjoys taking the time and attention to get the job done right. He enjoys soccer, basketball, and spending time with his young son';

// const JESSE_BROUHARD_BIO = 'Originally from New Jersey, moved to VA in 2016 for his automotive career. Spent the past 5 years as a Porsche technician and later became the shop ' +
//     'foreman at Porsche of Tysons Corner. With the birth of his first child looking for a change of work pace and lifestyle he joined the Roof-ER team to balance his time with ' +
//     'his growing family.'

// const DALE_ARBAUGH_BIO = 'Dale grew up in Southern Maryland where he was first introduced to the home remodeling business while working for his family\'s construction company. ' +
//     'After attending the College of Southern Maryland he found his way back to the remodeling business as a proud member of The Roof Docs. In his spare time, Dale enjoys ' +
//     'playing golf, fishing, and training. He looks forward to meeting and working with you'

// const MATT_BIO = 'Matt has over 5 years of experience in the Roofing/Home Remodeling industry.  Before that he attended West Virginia University in which he achieved majors in ' +
//     'Communication Studies, History, and Criminology.  Matt’s previous jobs were in logistics with the Department of Defense, freight brokering, and medical device sales. He enjoys spending ' +
//     'time near the water, his Goldendoodle Willis, and friends and family.  Matt finds his  gratitude through helping homeowners and improving the look of our neighborhoods. He is convinced ' +
//     'he holds the title of Mr. Basketball in the office.';
// const RYAN_FERGUSON_BIO = 'Ryan was born and raised in Northern Virginia where he studied Business Administration ' +
//     'and Communications. Prior to becoming a Roof Doctor, he worked as a marketing manager in the home ' +
//     'improvement industry where he developed a passion for helping customers love their homes. Ryan enjoys ' +
//     'spending time with friends, family, and his Border Collie Yawkey. He has requested to hire ' +
//     'Yawkey to help him with roof inspections, but it\'s against company policy to allow dogs on roofs.';
// const TIM_BLACKWELL_BIO = 'Tim is a born and raised native of the southern Maryland region. He grew up playing a variety of sports like baseball, soccer, ' +
//     'and football, and also spent his youth sharpening his performance in academics—especially math, science, and spanish. After High school, Tim attended college while ' +
//     'exploring different jobs, hobbies, skills, and volunteer work. After working in customer service, He noticed the success he had relative to his coworkers in terms of ' +
//     'keeping customer satisfaction at a maximum. Tim now uses what he has learned over the years to carry on at University as an Information Science undergraduate while ' +
//     'providing value with RoofER and their clients. In his down time, Tim is either trying new things, relaxing, exercising, or spending time with friends and family.';
// const CHASE_STRAIT_BIO = 'Chase was born in Alabama, and spent half of his life up through high school bouncing back in between Virginia and Alabama. While he has ' +
//     'the same stature as a ladder (tall and lanky), this is his first gig climbing up onto people’s roofs. Prior to becoming a roof doctor, he was an Event Manager at ' +
//     'Topgolf, where he focused on directing and overseeing events ranging from birthday parties, to the Redskins and Capitals, to the President of the World National ' +
//     'Bank. Chase went to Northern Virginia Community College, where he got his Associate’s in Business Administration, then continued to Longwood where he played ' +
//     'soccer and continued his Business Administration degree with a concentration in Cyber Security.  Chase is a passionate sports player, dog lover (has 2 ' +
//     'beautiful Huskies who he swears have worse ADHD than himself), and human garbage disposal when it comes to food.';
// const CORMAC_FORAN_BIO = 'Cormac grew up in the Annapolis area and attended Clemson university, graduating in 2018 with a bachelors of science in economics and a minor in management. ' +
//     'In his free time, he enjoys going to the beach and playing a variety of sports/pretty much anything that keeps him active. He also enjoys traveling and loves being outdoors (especially on roofs). ' +
//     'He is excited to be a part of the team!';

const EMPTY_BIO = '';

const DOCS = [
    { name: 'Michael Harvey', position: 'President / Job Lead', photo: Mike, bio: MIKE_BIO },
    { name: 'Oliver Brown', position: 'Manager', photo: Oliver, bio: OLIVER_BIO },
    { name: 'Greg C', position: 'Team Leader', photo: GregCampbell, bio: GREG_BIO },
    { name: 'Kyann H', position: 'Team Leader', photo: KyannHolman, bio: KYANN_HOLMAN_BIO },
    { name: 'Matt R', position: 'Team Leader', photo: MattRay, bio: MATT_RAY_BIO },
    { name: 'Ford B', position: 'Team Leader', photo: FordBarsi, bio: FORD_BIO },
    { name: 'Patrick R', position: 'Team Leader', photo: PatrickRobertson, bio: PATRICK_BIO },
    { name: 'Luis E', position: 'Team Leader', photo: LuisEsteves, bio: LUIS_ESTEVES_BIO },
    { name: 'Ismael C', position: 'Team Leader', photo: IsmaelCoreas, bio: ISMAEL_COREAS_BIO },
    { name: 'Mike R', position: 'Team Leader', photo: MikeRafter, bio: MIKE_RAFTER_BIO },
    { name: 'Amber C', position: 'Team Leader', photo: AmberCouch, bio: AMBER_COUCH_BIO },
    { name: 'Carlos D', position: 'Team Leader', photo: CarlosDavila, bio: CARLOS_DAVILA_BIO },
    { name: 'Zac N', position: 'Team Leader', photo: ZacNewdick, bio: ZAC_NEWDICK_BIO },
    { name: 'Chris R', position: 'Team Leader', photo: ChrisRamirez, bio: CHRIS_RAMIREZ_BIO },
    { name: 'Christian M', position: 'Team Leader', photo: ChristianM, bio: EMPTY_BIO },
    { name: 'Garrett W', position: 'Team Leader', photo: GarrettW, bio: EMPTY_BIO },
    { name: 'Jason B', position: 'Team Leader', photo: JasonB, bio: EMPTY_BIO },
    { name: 'Justin W', position: 'Team Leader', photo: JustinW, bio: EMPTY_BIO },

    { name: 'Mike T', position: 'Team Leader', photo: MikeT, bio: EMPTY_BIO },
    { name: 'Reese S', position: 'Team Leader', photo: ReeseS, bio: EMPTY_BIO },

    { name: 'Ahmed M', position: 'Team Leader', photo: AhmedM, bio: EMPTY_BIO },

    { name: 'Andre M', position: 'Team Leader', photo: AndreM, bio: EMPTY_BIO },
    { name: 'Chris F', position: 'Team Leader', photo: ChrisF, bio: EMPTY_BIO },
    { name: 'Chris T', position: 'Team Leader', photo: ChrisT, bio: EMPTY_BIO },
    { name: 'Corbin H', position: 'Team Leader', photo: CorbinH, bio: EMPTY_BIO },
    { name: 'Danny T', position: 'Team Leader', photo: DannyT, bio: EMPTY_BIO },
    { name: 'Dauson T', position: 'Team Leader', photo: DausonT, bio: EMPTY_BIO },

    { name: 'Dontel B', position: 'Team Leader', photo: DontelB, bio: EMPTY_BIO },
    { name: 'Dylan G', position: 'Team Leader', photo: DylanG, bio: EMPTY_BIO },
    { name: 'Freddy Z', position: 'Team Leader', photo: FreddyZ, bio: EMPTY_BIO },

    { name: 'Kyle M', position: 'Team Leader', photo: KyleM, bio: EMPTY_BIO },
    { name: 'Michael R', position: 'Team Leader', photo: MichaelR, bio: EMPTY_BIO },
    { name: 'Michael S', position: 'Team Leader', photo: MichaelS, bio: EMPTY_BIO },

    { name: 'Peyton F', position: 'Team Leader', photo: PeytonF, bio: EMPTY_BIO },
    { name: 'Richie R', position: 'Team Leader', photo: RichieR, bio: EMPTY_BIO },

    { name: 'Troy S', position: 'Team Leader', photo: TroyS, bio: EMPTY_BIO },
    { name: 'Will C', position: 'Team Leader', photo: WillC, bio: EMPTY_BIO },
    { name: 'Will S', position: 'Team Leader', photo: WillS, bio: EMPTY_BIO }


    // { name: 'Luca F', position: 'Team Leader', photo: LucaF, bio: EMPTY_BIO },
    // { name: 'Adel S', position: 'Team Leader', photo: AdelS, bio: EMPTY_BIO },
    // { name: 'Ali B', position: 'Team Leader', photo: AliB, bio: EMPTY_BIO },
    // { name: 'Dennis K', position: 'Team Leader', photo: DennisK, bio: EMPTY_BIO },
    // { name: 'Dom K', position: 'Team Leader', photo: DomK, bio: EMPTY_BIO },
    // { name: 'Jake B', position: 'Team Leader', photo: JakeB, bio: EMPTY_BIO },
    // { name: 'Jalyn M', position: 'Team Leader', photo: JalynM, bio: EMPTY_BIO },
    // { name: 'Javier K', position: 'Team Leader', photo: JavierK, bio: EMPTY_BIO },
    // { name: 'Joey H', position: 'Team Leader', photo: JoeyH, bio: EMPTY_BIO },
    // { name: 'John L', position: 'Team Leader', photo: JohnL, bio: EMPTY_BIO },
    // { name: 'Jon J', position: 'Team Leader', photo: JonJ, bio: EMPTY_BIO },
    // { name: 'Moe I', position: 'Team Leader', photo: MoeI, bio: EMPTY_BIO },
    // { name: 'Nico W', position: 'Team Leader', photo: NicoW, bio: EMPTY_BIO },
    // { name: 'Siraje J', position: 'Team Leader', photo: SirajeJ, bio: EMPTY_BIO },
    // { name: 'Steven W', position: 'Team Leader', photo: StevenW, bio: EMPTY_BIO },
    // { name: 'Patrick O', position: 'Team Leader', photo: PatrickOHara, bio: PATRICK_OHARA_BIO },
    // { name: 'Kaleb K', position: 'Team Leader', photo: KalebKuschel, bio: KALEB_KUSCHEL_BIO },
    // { name: 'Alex C', position: 'Team Leader', photo: AlexCampos, bio: ALEX_CAMPOS_BIO },
    // { name: 'TJ Spencer', position: 'Team Leader', photo: TJSpencer, bio: TJ_SPENCER_BIO },
    // { name: 'Asel T', position: 'Team Leader', photo: AselT, bio: EMPTY_BIO },
    // { name: 'Eric M', position: 'Team Leader', photo: EricM, bio: EMPTY_BIO },
    // { name: 'Ryan V', position: 'Team Leader', photo: RyanV, bio: EMPTY_BIO },
    // { name: 'Joel H', position: 'Team Leader', photo: JoelH, bio: EMPTY_BIO },
    // { name: 'John C', position: 'Team Leader', photo: JohnC, bio: EMPTY_BIO },
    // { name: 'Mary Lombardi', position: 'Executive Assistant', photo: MaryLombardi, bio: MARY_BIO },
    // { name: 'Rees Nalley', position: 'President / Job Lead', photo: Rees, bio: REES_BIO },
    // { name: 'Hunter Arnold', position: 'Team Leader', photo: HunterArnold, bio: HUNTER_ARNOLD_BIO },
    // { name: 'Aram Ghanbari', position: 'Team Leader', photo: AramGhanbari, bio: ARAM_GHANBARI_BIO },
    // { name: 'Zach Myers', position: 'Team Leader', photo: ZachMyers, bio: ZACH_MYERS_BIO },
    // { name: 'Aryan Ghaemi', position: 'Team Leader', photo: AryanGhaemi, bio: ARYAN_GHAEMI_BIO },
    // { name: 'Francis Kang', position: 'Team Leader', photo: FrancisKang, bio: FRANCIS_KANG_BIO },
    // { name: 'Kyle Gott', position: 'Team Leader', photo: KyleGott, bio: KYLE_GOTT_BIO },
    // { name: 'Allen McKelton', position: 'Team Leader', photo: AllenMckelton, bio: ALLEN_MCKELTON_BIO },
    // { name: 'Ryan Hall', position: 'Team Leader', photo: RyanHall, bio: RYAN_HALL_BIO },
    // { name: 'Noe Aleman', position: 'Team Leader', photo: NoeAleman, bio: NOE_ALEMAN_BIO },
    // { name: 'Dave LaGarde', position: 'Team Leader', photo: DaveLaGarde, bio: DAVE_LAGARDE_BIO },
    // { name: 'Joe McElhenny (Mac)', position: 'Team Leader', photo: JoeMac, bio: JOE_MAC_BIO },
    // { name: 'Daniel Keene', position: 'Team Leader', photo: DanielKeene, bio: DANIEL_KEENE_BIO },
    // { name: 'James McCarty', position: 'Team Leader', photo: JamesMcCarty, bio: JAMES_MCCARTY_BIO },
    // { name: 'Mike Mac', position: 'Team Leader', photo: MikeMac, bio: MIKE_MAC_BIO },
    // { name: 'Jimmy Snyder', position: 'Team Leader', photo: JimmySnyder, bio: JIMMY_SNYDER_BIO },
    // { name: 'Ethan D\'Souza', position: 'Team Leader', photo: EthanDsouza, bio: ETHAN_DSOUZA_BIO },
    // { name: 'Bryam Godoy', position: 'Team Leader', photo: BryamGodoy, bio: BRYAM_GODOY_BIO },
    // { name: 'Jesse Brouhard', position: 'Team Leader', photo: JesseBrouhard, bio: JESSE_BROUHARD_BIO },
    // { name: 'Dale Arbaugh', position: 'Team Leader', photo: DaleArbaugh, bio: DALE_ARBAUGH_BIO }
    // { name: 'Chris Davis', position: 'Manager', photo: ChrisDavis, bio: CHRIS_BIO },
    // { name: 'Austin Stephens', position: 'Team Leader', photo: AustinStephens, bio: AUSTIN_BIO },
    // { name: 'Hazem A', position: 'Team Leader', photo: HazemAtallah, bio: HAZEM_ATALLAH_BIO },


];

class MeetTheDocs extends Component {

    async componentDidMount() {

        const response = await fetch('https://geolocation-db.com/json/');
        const data = await response.json();

        // console.log(data);
        const time = moment().format('DD/MM/YYYY h:mm A')
        const log = `${time}: ${data.city}, ${data.state} - ${data.IPv4}\n`

        const config = { headers: { 'Access-Control-Allow-Origin': '*' } };

        axios.post(`${process.env.REACT_APP_API_URL}inquiry/docs`,
            { log: log }, config
        ).then(function (response) {
        }).catch(function (error) {
            console.log(error);
        });
    }

    render() {
        return (
            <div className="meet-the-docs-container">

                <div className="docs-banner">
                    <div className="docs-banner-text-container">
                        <p className="banner-text-small"> INTEGRITY </p>
                        <p className="banner-text-large"> Honesty First. And Second. And Third... </p>
                    </div>
                    <img className="banner-icon" src={BANNER_ICON_UP} alt="Banner Icon" />
                </div>

                <p className="docs-oath"> Our Hippocratic Oath: to hold a fiduciary responsibility to each and every one of our customers through a commitment to our core values of integrity, quality, and simplicity </p>

                <div className="docs-list-container">
                    { DOCS.map( (doc,index) => (
                        <DocDisplay key={doc.name} parent={this} doc={doc} index={index} />
                    ))}
                </div>

                <div className="gray-banner" />
                <FreeQuote />

                <ViewDocModal ref="viewDoc"/>
            </div>
        );
    }

    showDoc(index) {

        // TODO: Set Doc
        this.refs.viewDoc.setState({ doc: DOCS[index]});
        this.refs.viewDoc.show();
    }
}

class DocDisplay extends Component {

    showDocBio = e => {

        e.preventDefault();
        this.props.parent.showDoc( this.props.index);
    };

    render()  {

        return (
            <div className="doc-container">
                <div className="doc-image-container">
                    <img src={this.props.doc.photo} alt={this.props.doc.name}/>
                </div>
                <div className="doc-icon-container">
                    <img className="doc-expand-icon" onClick={this.showDocBio} src={ EXPAND_ICON } alt="Doc Bio"/>
                </div>
                {/*<p className="doc-position"> {this.props.doc.position }</p>*/}
                <p className="doc-name"> {this.props.doc.name }</p>
            </div>
        )
    }
}


export default MeetTheDocs;

class ViewDocModal extends Component {

    constructor(props){
        super(props);

        this.state = { hidden: true, doc: null };

    }

    show() {
        this.setState({ hidden: false });
    }


    closeModal() {

        this.setState({hidden: true});
    }


    render() {
        if(this.state.doc) {
            return (
                <div className= {"modalContainer" + (this.state.hidden ? ' hiddenModal' : '')} onClick={this.closeModal.bind(this)}>
                  <Helmet>
                       <title>Our Team | Tysons Corner Roofing Company | Roof-ER</title>
                       <meta name="description" content="Meet our experienced team of local VA roofers. Our technicians make us the top roof repair company in Tysons! Call us to schedule your free roof inspection!" />
                   </Helmet>
                    <div className="view-doc-modal-content">

                        <div className="doc-container-modal">
                            <div className="doc-image-container-modal">
                                <img src={this.state.doc.photo} alt={this.state.doc.name}/>
                            </div>
                            <div className="doc-icon-container-modal">
                                <img className="doc-expand-icon" onClick={this.closeBio} src={ COLLAPSE_ICON } alt="Collapse Bio"/>
                            </div>
                            {/*<p className="doc-position"> {this.state.doc.position }</p>*/}
                            <p className="doc-name"> {this.state.doc.name }</p>
                            <div className="doc-bio-modal-content">
                                <p> { this.state.doc.bio }</p>
                            </div>
                        </div>

                    </div>
                </div>
            );
        } else {
            return ( <div /> )
        }

    }

    closeBio = e => {
        e.preventDefault();
        this.setState ({ doc: null, hidden: true });
    }
}
