import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

// Styling
import '../../../style/components/blog/articleTemplate.scss';

import BANNER_ICON_UP from "../../../resources/images/misc/plus_icon_up.png";

class RightContractor extends Component {

    constructor(props) {
        super(props);

        this.state = { };
    }



    render() {

        const search = window.location.search;
        const params = new URLSearchParams(search);
        const ref = params.get('ref') ? params.get('ref') : '0';

        return (
            <div className="blog-template-container">
                <div className="blog-template-banner">
                    <div className="blog-template-text-container">
                        <p className="banner-text-small"> Blog </p>
                        <p className="banner-text-large"> Learn More About Roofing From Roof-ER </p>
                    <Helmet>
                        <title>Find the Right Roofing Contractor | Roof-ER</title>
                        <meta name="description" content="Read our tips on finding the right roofing contractor. Learn from the roofing experts in Fairfax County. If you're in need of roofing services contact us!"/>
                    </Helmet>
                    </div>
                    <img className="banner-icon" src={BANNER_ICON_UP} alt="Banner Icon Up" />
                </div>
                <div className="blog-template-content-container">
                    <div className="blog-template-content">
                        <div className="blog-item-container">
                            <div className="blog-item-info-container">
                                <a href="/blog"><button className="blog-home-button"><span className="arrow-left"> Back to Articles </span> </button></a>
                                <div className="blog-item-info-container-controls">
                                    <a href={`/blog?ref=${ref}&dir=prev`}><button  className="blog-previous-button"><span className="arrow-left"> Previous Article </span> </button></a>
                                    <a href={`/blog?ref=${ref}&dir=next`}><button className="blog-next-button"><span className="arrow-right"> Next Article </span> </button></a>
                                </div>
                                <h1>How to Find the Right Roofing Contractor</h1>
                                <p className="main-text">Homeowners can expect to replace their roofs about every 25 to 30 years.&nbsp;If you're fairly certain that your&nbsp;<a href="/services/roofrepair">roof</a>&nbsp;is nearing the end of its functional life, now is the time to begin screening roofers to talk about&nbsp;<a href="/services/roofreplacement">replacement</a>. Below are a few tips to help you pick the best roofer for your needs and feel confident about your decision.</p>
                                <h2>Only Consider Roofers With Licenses and Insurance</h2>
                                <p>One of the top things to consider when hiring a roofer is their licenses and insurance. Never assume that every roofer has an up-to-date license or current insurance coverage. Always ask for this information to protect both yourself and the roofer.</p>
                                <p>Quality, professional roofers will be happy to give you their license and insurance information because they have nothing to hide. If you sense hesitation, move on from that option.</p>
                                <h2>Prepare to Get Bids From at Least Three Roofers</h2>
                                <p>Even if you like the first roofer you screen, make sure you get evaluations and bids from at least two others. Having several roofing project bids will allow you to make a more informed decision. An important exception to this rule of thumb is <a href="/damage-covered">when your roof is being replaced through insurance</a>. In that situation, your cost is the same - your deductible - no matter which roofer you choose, so it's critical to find the one that you trust and will ensure a quality installation.</p>
                                <h2>Ask All Roofers the Same Questions</h2>
                                <p>A smart way to make your life easier is to ask every roofer you interview the same questions. Doing so will enable you to easily compare them. Some questions to ask a roofer before hiring the company include:</p>
                                <ul>
                                    <li>How long have you been in business?</li>
                                    <li>Can I get a copy of your license and insurance information?</li>
                                    <li>What is your typical work process like?</li>
                                    <li>How long do you think it would take to finish my roof?</li>
                                    <li>What roofing material recommendations would you suggest?</li>
                                    <li>How much time do I have before I need a new roof?</li>
                                    <li>Do you have your own employees or subcontract work instead?</li>
                                    <li>How soon could I get on your schedule?</li>
                                    <li>How soon can I get an estimate on the cost of a new roof?</li>
                                    <li>Can I see&nbsp;<a title="" href="/gallery" target="past roofing jobs">images of your past roofing work</a>?</li>
                                    <li>Are you willing to provide a list of references?</li>
                                </ul>
                                <p>Depending on your home and roofing needs, you may want to add other questions, and you should feel free to do so.&nbsp;</p>
                                <h2>Contact Each Roofer's References</h2>
                                <p>Paying for a new roof is a significant investment, so spend time collecting and calling all prospective roofing contractors' references. Ask the roofer's past customers what the experience was like. Was the roofer on time? Did the process feel seamless? Were the materials and installation as expected?</p>
                                <p>Most roofing clients will be happy to help you by giving you a good indication of how their experience with the roofer went. From their answers, you can decide what sounds right and narrow down your list of possible roofers. Don't forget to check out online reviews!</p>
                                <h2>Get All Bids in Writing and Prepare to Sign Contracts</h2>
                                <p>If a roofer tries to get you to say yes without a written, binding contract, choose a different contractor.&nbsp;Written agreements will protect you by outlining everything in black and white. Plus, all fees should be clearly noted.</p>
                                <h2>Ask If the Roofer Works With Insurance Companies</h2>
                                <p>Are you getting a new roof because of storm damage or other damage to your property? You may want to find a roofer that regularly works with insurance companies.&nbsp;Never assume that all roofers have this level of expertise. Partnering with one that does can save you time, money and many headaches throughout the process.</p>
                                <h2>Trust Your Instincts</h2>
                                <p>After reviewing every detail you've gathered, you should have a strong idea which roofer is the right fit. Remember, cost plays only a tiny role in choosing a roofer. Make sure you pick one who will deliver a finished product you can live with for decades.</p>
                                <p>Need roof replacement in&nbsp;<a href="/areas/vienna">Vienna, VA?</a>&nbsp;Contact Roof-ER for a free estimate by&nbsp;<a title="" href="/inspection" target="submitting a form online">submitting a form online</a>&nbsp;or calling&nbsp;703-239-3738.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default RightContractor;