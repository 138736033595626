import React, { Component } from 'react';
import { Route, BrowserRouter, Switch } from "react-router-dom";


import Navigation from "./components/navigation";
import Home from './components/home';
import OurProcess from './components/process';
import MeetTheDocs from './components/meetTheDocs';
import StormDamage from './components/stormDamage';
import FreeEstimate from './components/freeEstimate';
import EstimateExternal from './components/estimateExternal';
import Footer from './components/footer';
import MobileApps from './components/mobileApps';
import DevMobileApps from './components/devMobileApps';
import ContractorApps from './components/contractorApps';
import NotFound from './components/notFound';
import PrivacyPolicy from './components/privacyPolicy';
import EULA from './components/eula';
import FAQs from './components/faqs';
import OurMission from "./components/mission";
import OurValues from "./components/values";
import OurGallery from './components/gallery';
import RoofRepair from './components/services/roofrepair';
import SidingReplacement from './components/services/sidingreplacement';
import GuttersService from "./components/services/gutters";
import RoofReplacement from "./components/services/roofreplacement";
import ThankYou from "./components/thankyou";
import BlogHome from './components/blog/home';


// Blogs
import BestTime from './components/blog/articles/best-time';
import RightSiding from './components/blog/articles/right-siding';
import RightContractor from './components/blog/articles/right-contractor';
import ProtectingGutters from './components/blog/articles/protecting-gutters';
import HailDamageRoof from "./components/blog/articles/hail-damage-roof";
import RoofingCleanUp from "./components/blog/articles/roofing-clean-up";
import RoofVentilation from "./components/blog/articles/roof-ventilation";
import RoofFlashing from "./components/blog/articles/roof-flashing";
import RoofingFelt from "./components/blog/articles/roofing-felt";
import IceWaterShield from "./components/blog/articles/iws";
import DamageCovered from './components/blog/articles/damage-covered'
import AvoidingRoofingScams from "./components/blog/articles/avoiding-roofing-scams";
import RoofingSquare from "./components/blog/articles/roofing-square";
import HardboardSiding from './components/blog/articles/hardboard-siding';
import GettingANewRoof from './components/blog/articles/getting-a-new-roof';
import BestTimeToReplaceARoof from './components/blog/articles/best-time-to-replace-a-roof';


import DefectiveShinglesProgram from "./components/defectiveShinglesProgram";
import DefectiveShinglesProgramThankYou from "./components/defectiveShinglesProgramThankYou";

import DocLandingPage from "./components/docLandingPage";

// Service Areas
import Gaithersburg from "./components/serviceAreas/gaithersburg";
import Tysons from "./components/serviceAreas/tysons";
import Vienna from "./components/serviceAreas/vienna";
import Fairfax from "./components/serviceAreas/fairfax";
import SoffitVents from "./components/blog/articles/soffit-vents";
import ArchitecturalShingles from "./components/blog/articles/architectural-shingles";
import HorizontalVerticalSiding from "./components/blog/articles/horizontal-vertical-siding";

class App extends Component {


  render() {

    return (

        <BrowserRouter>
            <div className="App">
                <Navigation />
                    <Switch>
                        {/* Blog Posts */}
                        <Route path="/blog/best-time" component={BestTime} />
                        <Route path="/blog/right-siding" component={RightSiding} />
                        <Route path="/blog/right-contractor" component={RightContractor} />
                        <Route path="/blog/protecting-gutters" component={ProtectingGutters} />
                        <Route path="/blog/hail-damage" component={HailDamageRoof} />
                        <Route path="/blog/roofing-clean-up" component={RoofingCleanUp} />
                        <Route path="/blog/roofing-felt" component={RoofingFelt} />
                        <Route path="/blog/roof-ventilation" component={RoofVentilation} />
                        <Route path="/blog/roof-flashing" component={RoofFlashing} />
                        <Route path="/blog/ice-water-shield" component={IceWaterShield} />
                        <Route path="/blog/damage-covered" component={DamageCovered} />
                        <Route path="/blog/avoiding-roofing-scams" component={AvoidingRoofingScams} />
                        <Route path="/blog/roofing-square" component={RoofingSquare} />
                        <Route path="/blog/hardboard-siding" component={HardboardSiding} />
                        <Route path="/blog/horizontal-versus-vertical-siding" component={HorizontalVerticalSiding} />
                        <Route path="/blog/soffit-vents" component={SoffitVents} />
                        <Route path="/blog/architectural-shingles" component={ArchitecturalShingles} />
                        <Route path="/blog/getting-a-new-roof" component={GettingANewRoof} />
                        <Route path="/blog/best-time-for-new-roof" component={BestTimeToReplaceARoof} />


                        <Route path="/docs/:docCode" component={ DocLandingPage } />

                        <Route exact path="/" component={Home} />
                        <Route path="/process" component={OurProcess} />
                        <Route path="/docs" component={MeetTheDocs} />
                        <Route path="/damage" component={StormDamage} />
                        <Route path="/mission" component={OurMission} />
                        <Route path="/values" component={OurValues} />
                        <Route path="/gallery" component={OurGallery} />
                        <Route path="/faqs" component={FAQs} />
                        <Route path="/blog" component={BlogHome} />

                        <Route path="/areas/gaithersburg" component={Gaithersburg} />
                        <Route path="/areas/fairfax" component={Fairfax} />
                        <Route path="/areas/tysons" component={Tysons} />
                        <Route path="/areas/vienna" component={Vienna} />



                        <Route path="/services/roofrepair" component={RoofRepair} />
                        <Route path="/services/roofreplacement" component={RoofReplacement} />
                        <Route path="/services/sidingreplacement" component={SidingReplacement} />
                        <Route path="/services/gutters" component={GuttersService} />
                        <Route path="/inspection/thankyou" component={ThankYou} />
                        <Route path="/inspection/external" component={EstimateExternal} />
                        <Route path="/inspection" component={FreeEstimate} />
                        <Route path="/privacy" component={PrivacyPolicy} />
                        <Route path="/eula" component={EULA} />
                        <Route path="/downloads/apps/dev" component={DevMobileApps} />
                        <Route path="/downloads/apps" component={MobileApps} />
                        <Route path="/contractors/apps" component={ContractorApps} />

                        <Route path="/defective-shingles-program/thankyou" component={DefectiveShinglesProgramThankYou} />
                        <Route path="/defective-shingles-program" component={DefectiveShinglesProgram} />


                        <Route component={NotFound} />
                    </Switch>
                <Footer />
            </div>
        </BrowserRouter>
    );
  }
}

export default App;
