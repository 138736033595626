import React, { Component } from 'react';


// Styling
import '../style/components/notFound.scss';


class NotFound extends Component {


    render() {
        return (
            <div>
                <div className="notFoundContainer">
                    <p className="pageHeader"> The page you're looking for does not exist </p>
                    <p className="smallText-notFound"> ROOF<span className="logo-text">ER</span> Other Pages </p>
                    <p className="largeText-notFound"> Check out our other pages! </p>
                    <a href="/mission">Our Mission</a>
                    <a href="/values">Our Values</a>
                    <a href="/docs">Meet Our Team</a>
                    <a href="/work">View Our Excellent Work</a>
                    <a href="/faqs">Frequently Asked Questions</a>
                    <a href="/inspection">Contact Us For A Free Inspection</a>
                </div>
            </div>
        );
    }
}

export default NotFound;