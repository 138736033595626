import React, { Component } from 'react';
import { Helmet } from 'react-helmet';



// Styling
import '../../style/components/blog/home.scss';

// Banner
import BANNER_ICON_UP from '../../resources/images/misc/plus_icon_up.png';

const BlOG_POSTS = [
    { title: 'Choosing the Right Vinyl Siding for Your Home', link: '/blog/right-siding', index: 1 },
    { title: 'How to Find the Right Roofing Contractor', link: '/blog/right-contractor', index: 2 },
    { title: 'Protecting your Gutters from Storm Damage', link: 'blog/protecting-gutters', index: 3 },
    { title: 'When is the Best Time for Roofing Repairs or Replacements', link: '/blog/best-time', index: 4 },
    { title: 'What size hail will damage a roof', link: '/blog/hail-damage', index: 5 },
    { title: 'Everything you need to know about roofing clean up', link: '/blog/roofing-clean-up', index: 6 },
    { title: 'What you need to know about roofing felt', link: '/blog/roofing-felt', index: 7 },
    { title: 'When does a roof need ventilation?', link: '/blog/roof-ventilation', index: 8 },
    { title: 'What is roof flashing?', link: '/blog/roof-flashing', index: 9 },
    { title: 'What You Need To Know About Ice & Water Shield', link: '/blog/ice-water-shield', index: 10 },
    { title: 'What Kind of Roof Damage Is Covered By Insurance?', link: '/blog/damage-covered', index: 11 },
    { title: 'How to Avoid Roofing Scams', link: '/blog/avoiding-roofing-scams', index: 12 },
    { title: 'What Is a Roofing Square?', link: '/blog/roofing-square', index: 13 },
    { title: 'What is Hardboard Siding?', link: '/blog/hardboard-siding', index: 14 },
    { title: 'Horizontal vs. Vertical Siding', link: '/blog/horizontal-versus-vertical-siding', index: 15 },
    { title: 'What are Soffit Vents?', link: '/blog/soffit-vents', index: 16 },
    { title: 'What are Architectural Shingles?', link: '/blog/architectural-shingles', index: 17 },
    { title: 'Getting A New Roof', link: '/blog/getting-a-new-roof', index: 18 },
    { title: 'The Best Time to Replace a Roof', link: '/blog/best-time-for-new-roof', index: 19 }
];


class BlogHome extends Component {

    constructor(props) {
        super(props);

        this.state = { };
    }

    componentDidMount() {

        const search = window.location.search;
        const params = new URLSearchParams(search);
        const ref = params.get('ref') ? parseInt(params.get('ref')) : 0;
        const dir = params.get('dir')
        if( ref > 0 && dir){

            // Go back
            if(dir === 'prev'){
                // Go to last article
                if(ref === 1){
                    let newIndex = BlOG_POSTS.length - 1;
                    const post = BlOG_POSTS[newIndex]
                    window.location.href = `${post.link}?ref=${newIndex}`;

                } else {
                    // We are not indexing at 0, which is why the offset is 2 instead of 1 for a standard array
                    const post = BlOG_POSTS[ref - 2]
                    const newIndex = ref - 1
                    window.location.href = `${post.link}?ref=${newIndex}`;
                }

            } else {

                // Go to first article
                if(ref === BlOG_POSTS.length - 1){
                    let newIndex = 1
                    const post = BlOG_POSTS[newIndex - 1]
                    window.location.href = `${post.link}?ref=${newIndex}`;

                } else {
                    // We are not indexing at 0, which is why the offset is 2 instead of 1 for a standard array
                    const post = BlOG_POSTS[ref + 1]
                    const newIndex = ref + 1
                    window.location.href = `${post.link}?ref=${newIndex}`;
                }

            }



        }


    }


    render() {

        return (
            <div className="blog-container">
                <div className="blog-banner">
                    <div className="blog-banner-text-container">
                        <p className="banner-text-small"> Blog </p>
                        <p className="banner-text-large">  Learn More About Roofing From Roof-ER </p>
                <Helmet>
                        <title>Roofing Blog Archive | Learn More | Roof-ER </title>
                        <meta name="description" content="Learn everything you need to know about the roofing process in Fairfax County from the experts at Roof-ER. Contact us today for a free roof inspection!"/>
                </Helmet>
                    </div>
                    <img className="banner-icon" src={BANNER_ICON_UP} alt="Banner Icon Up" />
                </div>
                <div className="blog-container">
                    <div className="blog-content">

                        { BlOG_POSTS.map( (post, index) => (
                           <BlogPostCard posts={BlOG_POSTS} key={index} parent={this} post={post}/>
                        ))}

                    </div>
                </div>
            </div>
        );
    }

    showBlogPost ( post ){
        console.log(post)
    }

    showNext( index ){

        if(index === BlOG_POSTS.length - 1){
            console.log('Show first')
        } else {
            console.log('Show next')
        }

    }
    showPrevious(index){

        if(index === 0){
            console.log('Show LAST')
        } else {
            console.log('Show previous')
        }

    }
}

export default BlogHome;


class BlogPostCard extends Component {


    render (){
        return (
            <a href={`${this.props.post.link}?ref=${this.props.post.index}`}>
                <div className="blog-post-card" onClick={this.cardClicked}>
                    <p className="title"> { this.props.post.title} </p>
                </div>
            </a>
        )
    }

    cardClicked = () => {
        this.props.parent.showBlogPost( this.props.post )
    }
}