import React, { Component } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { geocodeByPlaceId } from 'react-places-autocomplete';

// STYLING
import '../../style/components/custom/programInput.scss';


class ProgramAddressInput extends Component {


    constructor(props) {
        super(props);

        this.state = { address: '', focused: false};
        this.inputRef =  React.createRef();
    }


    render() {


        const hideContent = this.state.focused || this.state.address !==  '';

        return (
            <div className="program-input-container address">
                <label onClick={this.titleClicked} style={hideContent ? {'display': 'none'} : {}}>
                    ADDRESS<span className="roofDocsRed"> *</span>
                </label>
                <PlacesAutocomplete
                    value={this.state.address}
                    onChange={this.handleChange}
                    onSelect={this.handleSelect}
                    shouldFetchSuggestions={this.state.address.length > 2}
                >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div className="places-search-container" >
                            <input
                                ref={this.inputRef}
                                autoComplete="no"
                                {...getInputProps({
                                    placeholder: '',
                                })}
                            />
                            <div className="autocomplete-dropdown-container">
                                {/*{loading && <div>Loading...</div>}*/}
                                {suggestions.map( (suggestion, index) => {
                                    const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                        ? { backgroundColor: '#dadada', cursor: 'pointer' }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                    return (
                                        <div key={index}
                                             {...getSuggestionItemProps(suggestion, {
                                                 className,
                                                 style,
                                             })}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </PlacesAutocomplete>
                {/*<input onChange={this.valueChanged}*/}
                {/*       onFocus={() => { this.setState({ focused: true })}}*/}
                {/*       onBlur={() => { this.setState({ focused: false })}}*/}
                {/*       value={this.state.value}*/}
                {/*       ref={this.inputRef}*/}
                {/*       placeholder='' />*/}
            </div>

        );
    }

    titleClicked = () => {

        this.setState({ focused: true });
        this.inputRef.current.focus();

    };

    handleChange = e => {

        this.setState({ address: e });
    };

    handleSelect = async (address, placeId) => {


        const [place] = await geocodeByPlaceId(placeId);


        const { long_name: streetNumber = '' } = place.address_components.find(c => c.types.includes('street_number')) || {};
        const { short_name: addressLine1 = '' } = place.address_components.find(c => c.types.includes('route')) || {};
        const { long_name: city = '' } = place.address_components.find(c => c.types.includes('locality')) || {};
        const { short_name: state = '' } = place.address_components.find(c => c.types.includes('administrative_area_level_1')) || {};
        const { long_name: zipCode = '' } = place.address_components.find(c => c.types.includes('postal_code')) || {};

        const selected = {
            streetNumber, addressLine1, city, state, zipCode
        };

        let value = '';
        if(selected.streetNumber){
            value = selected.streetNumber
        }
        if(selected.addressLine1){
            value = value === '' ? selected.addressLine1 : `${value} ${selected.addressLine1}`
        }
        if(selected.city){
            value += `, ${selected.city}`
        }

        if(selected.state){
            value += `, ${selected.state}`
        }
        if(selected.zipCode){
            value += ` ${selected.zipCode}`
        }

        this.setState({ address: value });
        this.inputRef.current.blur();
        this.props.parent.setState({ selectedAddress: selected })

        // console.log("postalCode",postalCode);
    };
}

export default ProgramAddressInput;