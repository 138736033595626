import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

// Styling
import '../../../style/components/mission.scss';
import '../../../style/components/work.scss';

import BANNER_ICON_UP from "../../../resources/images/misc/plus_icon_up.png";

class HailDamageRoof extends Component {

    constructor(props) {
        super(props);

        this.state = { };
    }



    render() {

        const search = window.location.search;
        const params = new URLSearchParams(search);
        const ref = params.get('ref') ? params.get('ref') : '0';

        return (
            <div className="blog-template-container">
                <div className="blog-template-banner">
                    <div className="blog-template-text-container">
                        <p className="banner-text-small"> Blog </p>
                        <p className="banner-text-large"> Learn More About Hail Damage to Roofs From Roof-ER </p>
                    <Helmet>
                        <title>What Size Hail Will Damage a Roof? | Roof-ER</title>
                        <meta name="description" content="Can 1/2 inch hail damage a roof? Learn what size hail can damage a roof from the experts at Roof-ER and schedule a free roof inspection in VA or MD today!"/>
                    </Helmet>
                    </div>
                    <img className="banner-icon" src={BANNER_ICON_UP} alt="Banner Icon Up" />
                </div>
                <div className="blog-template-content-container">
                    <div className="blog-template-content">
                        <div className="blog-item-container">
                            <div className="blog-item-info-container">
                                <a href="/blog"><button className="blog-home-button"><span className="arrow-left"> Back to Articles </span> </button></a>
                                <div className="blog-item-info-container-controls">
                                    <a href={`/blog?ref=${ref}&dir=prev`}><button  className="blog-previous-button"><span className="arrow-left"> Previous Article </span> </button></a>
                                    <a href={`/blog?ref=${ref}&dir=next`}><button className="blog-next-button"><span className="arrow-right"> Next Article </span> </button></a>
                                </div>
                                <h1><strong>What Size Hail Will Damage a Roof?</strong></h1>
                                <p>In the upper portions of thunderstorm clouds, the air can be cold enough for water to freeze and form into hail. Hailstones can range in size from 5 millimeters to ones that weigh well over a pound. While you may assume that a large hailstone can wreak havoc on your&nbsp;<a href="/services/roofrepair">roof</a>, many homeowners don't realize that small, pea sized hail can lead to problems as well.</p>
                                <p>The good news is that many&nbsp;<a href="/blog/damage-covered">homeowner insurance policies</a>&nbsp;will cover hail storm roof damage. However, you need to file a claim soon after the storm so the insurance company can verify the damage was caused by hail and not another issue. Since hail damage to a roof is not always visible or obvious, it&rsquo;s a good idea to get a professional roof inspection after a hailstorm to rule out any hail damage on the roof.&nbsp;</p>
                                <h2>Sizes of Hail to Cause Damage to a Shingle Roof</h2>
                                <p>The level of damage after a storm can depend on the type and size of hail you experience:</p>
                                <ul>
                                    <li><strong><strong>Pea-sized hail:</strong></strong>&nbsp;Small hail is less likely to cause direct damage unless there are very strong winds. However, if your area experiences small, pea size hail often, it can slowly damage shingles over time. Keep in mind that hail damage on roofs from smaller-sized hail can be hard to notice without professional help.</li>
                                    <li><strong><strong>Golf ball hail:</strong></strong>&nbsp;Any hailstones which are the size of a golf ball can cause damage to flashing, shingles and other parts of your roof.&nbsp; Even if you don&rsquo;t see obvious damage, you should contact a roofing professional for a <a href="https://theroofdocs.com/inspection">free hail damage inspection</a>.</li>
                                    <li><strong><strong>Large hail:</strong></strong>&nbsp;Any hail larger than a golf ball is likely to cause significant damage. These hailstones fall faster because of their size, hitting your roof with more force.</li>
                                </ul>
                                <p>Regardless of the hail size, any roof with hail damage will likely be a problem. Shingle damage from hail can cause a variety of issues, such as:</p>
                                <ul>
                                    <li>Lost shingle granules</li>
                                    <li>Bruising, which can make shingles feel softer than they should.</li>
                                    <li>Dents to the soft metal on your roof.</li>
                                    <li>Cracks.</li>
                                    <li>Exposed substrate.</li>
                                </ul>
                                <p>Any of these problems could reduce the longevity of your shingles and lead to leaks in your home.</p>
                                <h2><strong><strong>Can Quarter-Size Hail Damage a Roof?</strong></strong></h2>
                                <p>One challenge with hail is that it can be hard to gauge its exact size. By the time you see hail on the ground, it may have broken apart or melted. Those pea-sized hail clumps of ice may have been much larger when they hit your roof.</p>
                                <p>If you see quarter-size hail, you will want to schedule an inspection ASAP. Some hail damage may not be visible, but 1 inch hail can still damage a roof or cause leaks and other problems later. If you act soon after the storm, your insurance company may cover the cost of hail damage repairs.</p>
                                <h2><strong><strong>Do You Need Roof Repair and Replacement in Vienna, Virginia?</strong></strong></h2>
                                <p>If you live in the greater&nbsp;<a href="/areas/vienna">Vienna, Virginia area</a>&nbsp;and have recently had a hailstorm, you should check your roof for hail damage. Roof-ER can help. Call us at <a href="7032393738">703-239-3738</a> or contact us online to&nbsp;<a href="/inspection">schedule&nbsp;a free hail inspection</a>&nbsp;in Tysons. Our professional crew will thoroughly assess your roof for hail damage, offer an honest evaluation and set up an appointment to perform any necessary repairs.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default HailDamageRoof;