import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

// Styling
import '../../../style/components/blog/articleTemplate.scss';

import BANNER_ICON_UP from "../../../resources/images/misc/plus_icon_up.png";
import TitleImage from '../../../resources/images/blogs/01-title-roof-damage-insurance.png';
import PerilsImage from '../../../resources/images/blogs/02-perils.png'
import CTA from '../../../resources/images/blogs/03-CTA.png';

class DamageCovered extends Component {

    constructor(props) {
        super(props);

        this.state = { };
    }



    render() {

        const search = window.location.search;
        const params = new URLSearchParams(search);
        const ref = params.get('ref') ? params.get('ref') : '0';

        return (
            <div className="blog-template-container">
                <div className="blog-template-banner">
                    <div className="blog-template-text-container">
                        <p className="banner-text-small"> Blog </p>
                        <p className="banner-text-large"> Learn More About Roofing From Roof-ER </p>
                        <Helmet>
                            <title>What Kind of Roof Damage Is Covered By Insurance? | Roof-ER</title>
                            <meta name="description" content="Learn about the types of roof damage covered by homeowners insurance and find out what's covered by your insurance provider. Contact Roof-ER for roof replacement services in Tysons."/>
                        </Helmet>
                    </div>
                    <img className="banner-icon" src={BANNER_ICON_UP} alt="Banner Icon Up" />
                </div>
                <div className="blog-template-content-container">
                    <div className="blog-template-content" style={{ width: '100%'}}>
                        <div className="blog-item-container">
                            <div className="blog-item-info-container-full">
                                <a href="/blog"><button className="blog-home-button"><span className="arrow-left"> Back to Articles </span> </button></a>

                            </div>
                            <div className="blog-item-info-container-full">
                                <a href={`/blog?ref=${ref}&dir=prev`}><button  className="blog-previous-button"><span className="arrow-left"> Previous Article </span> </button></a>
                                <a href={`/blog?ref=${ref}&dir=next`}><button className="blog-next-button"><span className="arrow-right"> Next Article </span> </button></a>
                            </div>
                            <div className="blog-item-info-container-full">
                                <h1>What Kind of Roof Damage Is Covered By Insurance?</h1>
                            </div>
                            <img className="long-form-image" src={TitleImage} alt="Title" />
                            <div className="blog-item-info-container-full">
                                <p>As a homeowner, understanding the extent of your homeowners insurance can be crucial in case you ever face serious home destruction. Storms and accidents can cause roof damage, which you'll have to&nbsp;<a href="/roofrepair">repair</a>&nbsp;for your home's safety and integrity. Your roof is one of the most important parts of your home, keeping you and your belongings protected from the elements.</p>
                                <p>When you have a damaged roof, you'll need to know what costs are covered in your insurance policy and what costs are not. Does homeowners insurance cover roof damage? It depends: your homeowners insurance might cover the cost of roof repairs, depending on their cause and severity. If roof damage causes other property destruction, your policy might help cover those costs as well. Use this guide to help you determine whether or not your homeowners insurance will cover specific types of roof damage.</p>
                                <p>Your coverage depends on the type of policy you've purchased and your insurance company. Your homeowners insurance should have a "dwelling coverage" stipulation, which provides coverage for certain "perils." Perils are events that might damage your home or property that are beyond your control, like storms and disasters. Types of roof damage perils that are covered often include:</p>
                                <ul>
                                    <li>Fire damage</li>
                                    <li>Wind damage</li>
                                    <li>Hail damage</li>
                                    <li>Fallen tree damage</li>
                                </ul>
                            </div>
                            <img className="long-form-image" src={PerilsImage} alt="Perils" />
                            <div className="blog-item-info-container-full">
                                <p>Your policy might also include things like vandalism, riots or burglary. Check your policy to see which peril events it covers. If your roof damage is the result of a covered peril, your insurance company will likely help manage the repair costs. Keep in mind that you'll have to pay the deductible outlined in your contract and any coverage limits will apply. You may want to try to negotiate a low deductible when you sign your homeowners insurance contract.</p>
                                <p>Does insurance cover roof damage? Although insurance will help cover the costs of a damaged roof, some funds will have to come out-of-pocket, so it's important to be prepared and try to have some savings set aside. If a covered insurance claim results in destroyed objects within the home, your personal property coverage might help with those expenses.</p>
                                <h2>Does Homeowners Insurance Cover Roof Leaks?</h2>
                                <p>Roof leaks are common and might occur after a heavy rain or storm. Some homeowners insurance policies will cover a roof leak, depending on its cause. For instance, if the roof leak is the result of wind or hail damage and wind and hail are covered perils, your insurance may help repair the roof leak.</p>
                                <p>Is your roof leaking after heavy rain? Insurance may not cover it. Most homeowners insurance will not cover a roof leak if it's due to a lack of maintenance or regular wear and tear. Most insurers place responsibility on the homeowner to keep up routine roof maintenance tasks. Be sure to schedule regular, preventive maintenance to avoid age-related or wear-related leaks. Always ask about a roof's age and condition before purchasing a new home, and&nbsp;<a href="/inspection">schedule regular roof inspections</a>. It's important to keep up your end of responsibilities as a homeowner to optimize your insurance coverage</p>
                                <h2>Does Insurance Cover Storm Damage to a Roof?</h2>
                                <p>Storms can cause a lot of home damage. The most severe storms can rip homes apart, while milder storms can still cause extensive destruction. Whether or not you live in an area often hit by heavy storms, it's a good idea to prepare for the worst.&nbsp;<a href="protecting-gutters">Protect your home's gutters</a>&nbsp;and roof from potential storm damage. Moderate storms can still wreak havoc on a home's roof.&nbsp;<a href="/hail-damage">Even small hail can do serious damage</a>&nbsp;, as can excessive rainwater or electrical surges.</p>
                                <p>After a storm, you may notice roof damage. Your homeowners insurance might cover storm damage to your roof, but it depends on the policy and the damage's cause. Your policy will have specific guidelines when it comes to which storm damage it covers. Floods and earthquakes are not usually part of a standard policy, even if these events are common in your area. If you live in a flood-prone or earthquake-prone area, be sure to supplement your policy as needed. Is wind damage to roof covered by insurance? Yes, most insurance policies cover damage from ice, lightning strikes, wind, hail or fallen trees.</p>
                                <p>Note that water damage, even if the result of a natural disaster, is a complex issue and may or may not be included in your policy. If you're unsure what kind of water damage your homeowners insurance policy covers, be sure to speak with your agent or a company representative.</p>
                                <h2>What Kind of Roof Damage Is Not Covered?</h2>
                                <p>Insurance companies consider several factors when outlining their homeowner's policies. A roof's age is one of the most important considerations. Some insurers might refuse to renew coverage on a home with a roof over 20 years old. Some might mandate an inspection and refuse to renew coverage if the roof fails the inspection. In certain cases, your insurance company might only reimburse the value of the older roof instead of covering the repair costs.</p>
                                <p>Your insurer sets these standards because a roof older than 20 years might have difficult-to-detect issues, such as underlying water damage. In this case, a surface-level repair will not be enough &mdash; you may need&nbsp;<a href="/roofreplacement">a full roof replacement</a>. This will likely be worth the investment, as you'll have a much easier time getting sufficient insurance coverage in the event of a disaster. Insurers prefer to cover newer roofs since they're stronger and more reliable.</p>
                                <p>In some places, the demand for insurance outweighs the supply. This is especially true of coastal regions where hurricane damage is prevalent. In these areas, insurance companies tend to have strict inspection requirements before writing or renewing policies. If you have an old roof and have not scheduled an inspection recently, you might have a hard time getting your insurance company to cover roof damage costs. This is one reason regular roof inspections are a good idea.</p>
                                <h2>Contact Roof-ER for Roof Repair or Replacement</h2>
                                <p>Though roof damage is an unwelcome surprise, keeping up with regular roof inspection and repair can help you maintain essential homeowners insurance and get full coverage in the event of a disaster. Contact Roof-ER for roof inspection, repair or&nbsp;<a href="/roofreplacement">replacement</a>&nbsp;for your&nbsp;<a href="/vienna">Vienna, Virginia</a>&nbsp;home. Here at Roof-ER, we value&nbsp;<a href="/values">integrity, quality workmanship and simplicity</a>. We offer straightforward roof services at fair and honest prices.</p>

                            </div>
                            <a href="/inspection"><img className="long-form-image" src={CTA} alt="CTA" /></a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DamageCovered;