import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

// Styling
import '../../../style/components/blog/articleTemplate.scss';

import BANNER_ICON_UP from "../../../resources/images/misc/plus_icon_up.png";
import TitleImage from "../../../resources/images/blogs/hardboard-siding/01-hardboard-siding.jpg";
import HowCanITell from "../../../resources/images/blogs/hardboard-siding/02-How-Can-I-Tell-if-I-Have-Hardboard-Siding.jpg"
import Replaced from "../../../resources/images/blogs/hardboard-siding/03-Contact-Us-to-Get-Your-Pressboard-Siding-Replaced.jpg";

class HardboardSiding extends Component {

    constructor(props) {
        super(props);

        this.state = { };
    }


    render() {

        const search = window.location.search;
        const params = new URLSearchParams(search);
        const ref = params.get('ref') ? params.get('ref') : '0';

        return (
            <div className="blog-template-container">
                <div className="blog-template-banner">
                    <div className="blog-template-text-container">
                        <p className="banner-text-small"> Blog </p>
                        <p className="banner-text-large"> Learn More About Roofing From Roof-ER </p>
                        <Helmet>
                            <title>What Is Hardboard Siding? | Roof-ER</title>
                            <meta name="description" content="What is hardboard siding and is it the right fit for your home? Learn more from Roof-ER, the roofing experts in Fairfax County."/>
                        </Helmet>
                    </div>
                    <img className="banner-icon" src={BANNER_ICON_UP} alt="Banner Icon Up" />
                </div>
                <div className="blog-template-content-container">
                    <div className="blog-template-content" style={{ width: '100%'}}>
                        <div className="blog-item-container">
                            <div className="blog-item-info-container-full">
                                <a href="/blog"><button className="blog-home-button"><span className="arrow-left"> Back to Articles </span> </button></a>
                            </div>
                            <div className="blog-item-info-container-full">
                                <a href={`/blog?ref=${ref}&dir=prev`}><button  className="blog-previous-button"><span className="arrow-left"> Previous Article </span> </button></a>
                                <a href={`/blog?ref=${ref}&dir=next`}><button className="blog-next-button"><span className="arrow-right"> Next Article </span> </button></a>
                            </div>
                            <img className="long-form-image" src={TitleImage} alt="Title" />
                            <div className="blog-item-info-container-full">
                                <h1>What Is Hardboard Siding?</h1>
                                    <p>If your home was built in the 1970s or the 1980s, there's a good chance it has hardboard siding. Hardboard siding has a wood-like appearance and a low cost, making it affordable and attractive in construction.</p>
                                    <p>Until the mid-1990s, installations were happening nationwide &mdash; then homeowners began seeing deterioration among other issues with their hardboard siding. A class-action lawsuit was filed in 1994 against the most common hardwood siding manufacturers.</p>
                                    <p>Hardboard siding is made of wood fibers, flakes or chips mixed with glues and resins. During the hardboard manufacturing process, these materials are bound together using heat and pressure. Other names for hardboard siding are pressboard siding, synthetic wood siding and hardboard.</p>
                                    <p>Hardboard masonite siding was invented in the 1920s by William H. Mason. After the Second World War, masonite clapboard siding became a common residential construction material. The problems only began in the 1970s and 1980s when manufacturers were experimenting with the hardboard production process, causing some lines to not be sealed properly.</p>
                                    <p>As a result, the class-action lawsuit Naef v. Masonite Corp. emerged on a national level with other cases against leading hardboard manufacturers by anyone who owned property constructed with their hardboard siding. This lawsuit nearly wiped out the hardboard siding industry. Most manufacturers ceased production and offered warranties between 15 to 30 years for homeowners who had installed pressboard siding.</p>
                                <h2>How Long Does Hardboard Siding Last?</h2>
                                    <p>Hardboard siding can last up to 50 years, but the durability of your hardboard varies between manufacturers. Due to differences in the tempering process, some hardboards were built to last, while others were not.</p>
                                    <p>Another factor that greatly impacts how long pressboard siding can last is the upkeep. Hardboard siding that is annually maintained has a much better chance of lasting decades compared to siding that hasn't been cared for properly. Stringent maintenance on siding isn&rsquo;t something a typical homeowner is interested in, which makes hardboard siding problematic.</p>
                                <h2>What Maintenance Does Hardboard Siding Need?</h2>
                                    <p>Hardboard maintenance is dependent on the conditions it was installed in. If your hardboard masonite siding was installed by someone who wasn&rsquo;t a professional or didn&rsquo;t do enough research before installation, extra work may be required.</p>
                                    <p>Directing water drainage away from your home is important for your property in a number of ways. It can prevent flooding, structural damage, pipe issues and damage to your hardboard siding. Your drainage pipes or gutters should be directed away from the foundation of your home.&nbsp;It&rsquo;s also important to perform maintenance on drainage pipes to ensure they&rsquo;re free of clogging and obstructions.&nbsp;</p>
                                    <p>Another method to avoid water damage is to slope the ground away from your hardboard siding. Sloping the ground can also help you avoid issues with rot, mold and mildew.</p>
                                    <p>Be sure to perform routine checkups for any major flaws in your hardboard siding. Check for any cracked paint, missing sealant, holes, loose nails and other problems that could lead to moisture infiltration. Even a slight discoloration can be an indication of water absorption.</p>
                                    <p>Keeping nature away from your hardboard siding is also important to maintain the integrity of your siding. It&rsquo;s recommended to install hardboard siding at a distance of at least 6 inches from the ground, grass mulch and plants.</p>
                            </div>
                            <img className="long-form-image" src={HowCanITell} alt="How Can I Tell" />
                            <div className="blog-item-info-container-full">
                                <h2>How Can I Tell if I Have Hardboard Siding?</h2>
                                    <p>If you&rsquo;re unsure if your siding is made of hardboard, the best way to investigate is from the inside. Go to an unfinished area in your property, like the attic or the garage.</p>
                                    <p>Once you&rsquo;re in an unfinished area, peel back the tar paper from the back of your siding and look for markings. If you can locate the manufacturer&rsquo;s name or AHA (American Hardboard Association) code, you will be able to search the internet to find more information about your specific siding. If you can only find the AHA code, you can determine the manufacturer, type of siding and where it was made in a quick search.</p>
                                    <p>If you can&rsquo;t find any words or numbers, the best alternative would be to search for specific markings of popular pressboard siding manufacturers. Two of the top-selling brands of hardboard siding are&nbsp;Masonite&nbsp;and LP. A few other manufacturers in the hardboard industry include:</p>
                                        <ul>
                                            <li>Georgia Pacific (GP)</li>
                                            <li>ABTiCO</li>
                                            <li>ABITIBI-PRICE</li>
                                            <li>Weyerhaeuser</li>
                                            <li>Boise Cascade</li>
                                        </ul>
                                <h2>Issues With Pressboard Siding</h2>
                                    <p>The hardboard siding industry faced a class-action lawsuit because of the numerous issues homeowners were experiencing. The severity of issues is dependent on the tempering process of the manufacturer, as not all hardboard siding is made the same.</p>
                                    <p>All wood is porous, so it naturally absorbs water. Hardboard siding is no exception. When it absorbs water, it swells, and the swelling can be slight or problematic. With proper installation and ongoing maintenance, the absorption rate for your hardboard siding can be maintained at a safe level.</p>
                                    <p>The real issues occur when hardboard siding is improperly installed. Common pressboard siding problems include:&nbsp;&nbsp;</p>
                                        <ul>
                                            <li>Buckling</li>
                                            <li>Blistering</li>
                                            <li>Softening</li>
                                            <li>Rotting</li>
                                            <li>Mildew</li>
                                            <li>Severe swelling</li>
                                            <li>Insect infestation</li>
                                        </ul>
                                    <p>Water can pass through improperly caulked joints into sunken nail holes and cut edges located near the ground.</p>
                                    <p>If water can&rsquo;t escape your siding and the hardboard becomes waterlogged, the siding will form bulges, mildew and rot. If your pressboard was directly installed onto masonry materials, water can seep into the siding easily.</p>
                                <h2>What Can I Replace Hardboard Siding With?</h2>
                                    <p>Although hardboard siding may seem like an affordable and attractive option in the beginning, the time dedicated to maintenance and repair can quickly overtake the affordability. When it&rsquo;s time to&nbsp;<a href="/blog/right-siding">replace your hardboard siding</a>, vinyl siding&nbsp;is a great alternative that can increase the value of your home.</p>
                                    <p>Durable vinyl siding is designed to withstand the stress of the elements. Too much water is never an issue with vinyl siding. It&rsquo;s also stylish and attractive. You can choose from a multitude of colors and textures to best suit your home&rsquo;s style. Most importantly, vinyl siding is low-maintenance. Dirt and grime can be easily removed with a hose or power washer.</p>
                            </div>
                            <img className="long-form-image" src={Replaced} alt="Replaced" />
                            <div className="blog-item-info-container-full">
                                <h2>Contact Us to Get Your Pressboard Siding Replaced</h2>
                                <p>Hardboard siding is a material of the past. Years after the class-action lawsuit, homeowners haven&rsquo;t forgotten the failures of masonite clapboard siding. It&rsquo;s important to update your hardboard siding with a new siding that&rsquo;s built to last.</p>
                                <p>When you need siding replacement in Vienna or the surrounding communities, turn to the Roof Docs at Roof-ER.&nbsp;<a href="/inspection">Schedule your free siding inspection</a>&nbsp;in Fairfax County by calling 703-239-3738 today.</p>
{/*                                <script type="application/ld+json">*/}
{/*{*/}
{/*  "@context": "https://schema.org",*/}
{/*  "@type": "FAQPage",*/}
{/*  "mainEntity": [{*/}
{/*    "@type": "Question",*/}
{/*    "name": "What Is Hardboard Siding?",*/}
{/*    "acceptedAnswer": {*/}
{/*      "@type": "Answer",*/}
{/*      "text": "Hardboard siding is made of wood fibers, flakes or chips mixed with glues and resins. During the hardboard manufacturing process, these materials are bound together using heat and pressure. Other names for hardboard siding are pressboard siding, synthetic wood siding and hardboard."*/}
{/*    }*/}
{/*  },{*/}
{/*    "@type": "Question",*/}
{/*    "name": "How Long Does Hardboard Siding Last?",*/}
{/*    "acceptedAnswer": {*/}
{/*      "@type": "Answer",*/}
{/*      "text": "Hardboard siding can last up to 50 years, but the durability of your hardboard varies between manufacturers. Due to differences in the tempering process, some hardboards were built to last, while others were not.*/}

{/*Another factor that greatly impacts how long pressboard siding can last is the upkeep. Hardboard siding that is annually maintained has a much better chance of lasting decades compared to siding that hasn't been cared for properly. Stringent maintenance on siding isn’t something a typical homeowner is interested in, which makes hardboard siding problematic."*/}
{/*    }*/}
{/*  },{*/}
{/*    "@type": "Question",*/}
{/*    "name": "What Maintenance Does Hardboard Siding Need?",*/}
{/*    "acceptedAnswer": {*/}
{/*      "@type": "Answer",*/}
{/*      "text": "Hardboard maintenance is dependent on the conditions it was installed in. If your hardboard masonite siding was installed by someone who wasn’t a professional or didn’t do enough research before installation, extra work may be required."*/}
{/*    }*/}
{/*  },{*/}
{/*    "@type": "Question",*/}
{/*    "name": "What Can I Replace Hardboard Siding With?",*/}
{/*    "acceptedAnswer": {*/}
{/*      "@type": "Answer",*/}
{/*      "text": "Although hardboard siding may seem like an affordable and attractive option in the beginning, the time dedicated to maintenance and repair can quickly overtake the affordability. When it’s time to replace your hardboard siding, vinyl siding is a great alternative that can increase the value of your home."*/}
{/*    }*/}
{/*  }]*/}
{/*}*/}
{/*</script>*/}
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        )
    }
}

export default HardboardSiding;

// <script type="application/ld+json">
//     {
//         "@context": "https://schema.org",
//         "@type": "FAQPage",
//         "mainEntity": [
//     {
//         "@type": "Question",
//         "name": "What Is Hardboard Siding?",
//         "acceptedAnswer": {
//         "@type": "Answer",
//         "text": "Hardboard siding is made of wood fibers, flakes or chips mixed with glues and resins. During the hardboard manufacturing process, these materials are bound together using heat and pressure. Other names for hardboard siding are pressboard siding, synthetic wood siding and hardboard."
//     }
//
//     },
//     {
//         "@type": "Question",
//         "name": "How Long Does Hardboard Siding Last?",
//         "acceptedAnswer": {
//         "@type": "Answer",
//         "text": "Hardboard siding can last up to 50 years, but the durability of your hardboard varies between manufacturers. Due to differences in the tempering process, some hardboards were built to last, while others were not. Another factor that greatly impacts how long pressboard siding can last is the upkeep. Hardboard siding that is annually maintained has a much better chance of lasting decades compared to siding that hasn't been cared for properly. Stringent maintenance on siding isn’t something a typical homeowner is interested in, which makes hardboard siding problematic."
//     }
//
//     },{
//
//         "@type": "Question",
//
//         "name": "What Maintenance Does Hardboard Siding Need?",
//
//         "acceptedAnswer": {
//
//         "@type": "Answer",
//
//         "text": "Hardboard maintenance is dependent on the conditions it was installed in. If your hardboard masonite siding was installed by someone who wasn’t a professional or didn’t do enough research before installation, extra work may be required."
//
//     }
//
//     },{
//
//         "@type": "Question",
//
//         "name": "What Can I Replace Hardboard Siding With?",
//
//         "acceptedAnswer": {
//
//         "@type": "Answer",
//
//         "text": "Although hardboard siding may seem like an affordable and attractive option in the beginning, the time dedicated to maintenance and repair can quickly overtake the affordability. When it’s time to replace your hardboard siding, vinyl siding is a great alternative that can increase the value of your home."
//
//     }
//
//  ]
// </script>