import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

// Styling
import '../../../style/components/blog/articleTemplate.scss';

import BANNER_ICON_UP from "../../../resources/images/misc/plus_icon_up.png";

class ProtectingGutters extends Component {

    constructor(props) {
        super(props);

        this.state = { };
    }


    render() {

        const search = window.location.search;
        const params = new URLSearchParams(search);
        const ref = params.get('ref') ? params.get('ref') : '0';

        return (
            <div className="blog-template-container">
                <div className="blog-template-banner">
                    <div className="blog-template-text-container">
                        <p className="banner-text-small"> Blog </p>
                        <p className="banner-text-large"> Learn More About Roofing From Roof-ER </p>
                   <Helmet>
                        <title>Protecting Your Gutters From Storm Damage | Roof-ER</title>
                        <meta name="description" content="Discover the best ways to protect your gutters from storm damage. If you have damaged gutters,contact Roof-ER for a free inspection today!"/>
                    </Helmet>
                    </div>
                    <img className="banner-icon" src={BANNER_ICON_UP} alt="Banner Icon Up" />
                </div>
                <div className="blog-template-content-container">
                    <div className="blog-template-content">
                        <div className="blog-item-container">
                            <div className="blog-item-info-container">
                                <a href="/blog"><button className="blog-home-button"><span className="arrow-left"> Back to Articles </span> </button></a>
                                <div className="blog-item-info-container-controls">
                                    <a href={`/blog?ref=${ref}&dir=prev`}><button  className="blog-previous-button"><span className="arrow-left"> Previous Article </span> </button></a>
                                    <a href={`/blog?ref=${ref}&dir=next`}><button className="blog-next-button"><span className="arrow-right"> Next Article </span> </button></a>
                                </div>
                                <h1>Protecting Your Gutters From Storm Damage</h1>
                                <p className="main-text">Your&nbsp;<a href="/services/gutters">gutters</a>&nbsp;play an important role in keeping your home protected from moisture problems. However, they aren't invincible. That's why it's essential to physically and visually inspect your gutters once in a while, especially after a significant rain, wind or snowstorm.</p>
                                <p>By being diligent with the gutter inspection process, you can make sure your gutters are doing their job and avoid potentially costly issues later.</p>
                                <h2>Why Storm Damage Happens to Gutters</h2>
                                <p>Because gutters are installed on the outside of your residence, they are constantly exposed to the elements. That means even the most securely attached, high-quality gutters can be at risk of getting damaged when the weather turns nasty.</p>
                                <h2>Common Types of Gutter Storm Damage</h2>
                                <p>Sometimes, gutter damage is very obvious. Other times, you won't be able to tell that your gutters have been affected after a storm until you inspect them more carefully.</p>
                                <p>After a storm, be sure to check your gutters for:&nbsp;</p>
                                <ul>
                                    <li><strong>Sagging:</strong>&nbsp;If your gutters have started to sag and buckle in certain spots, they may be weighed down with ice or debris. Alternatively, they may have been hit by tree limbs and knocked out of place.</li>
                                    <li><strong>Overflowing:</strong>&nbsp;Gutters that are overflowing with water instead of draining properly are usually clogged somewhere within the trough or the downspout.</li>
                                    <li><strong>Leaking:</strong>&nbsp;A leaking gutter allows water to seep out where it shouldn't. Leaking frequently happens along the gutter joints where the different pieces come together.</li>
                                    <li><strong>Broken parts:&nbsp;</strong>During your walk around the house, you may spot broken areas of your gutter. These could be dangling from the roof or walls, or already on the ground.</li>
                                    <li><strong>Uneven troughs:&nbsp;</strong>An uneven trough may mean that the gutter has come loose, making it impossible for it to collect and drain water effectively.</li>
                                </ul>
                                <p>If you notice any of these gutter damage signs, be sure to make time to get your gutters back in working order before the next downpour.</p>
                                <h2>Steps to Minimize Storm Damage</h2>
                                <p>In addition to staying on top of concerns like gutter hail damage, take a few precautionary measures to minimize the effects of storms on your gutters:</p>
                                <ul>
                                    <li>Always check your gutters after major storms sweep through your area.</li>
                                    <li>Clean your gutters on a routine basis.</li>
                                    <li>Extend your downspouts to a proper length away from your home's foundation to minimize moisture build-up around your foundation.</li>
                                    <li>Stay on top of subtle indicators of gutter damage, such as an overworked sump pump, obvious leaking into your basement or garage, gutters that can't seem to handle even mild rainstorms and the sound of a broken gutter hitting against the side of the roof.</li>
                                    <li>Either fix compromised gutters yourself or call a professional for help.</li>
                                </ul>
                                <h2>Giving Your Gutters a Check-Up</h2>
                                <p>Properly caring for your gutters will help ensure they perform perfectly throughout storm after storm. And that'll give you tremendous peace of mind, no matter how intensely the rain falls.</p>
                                <p>Has it been a while since you've had an expert take a look at your gutters? If you're a<a href="/areas/vienna"> Vienna</a>&nbsp;or&nbsp;<a href="/areas/fairfax">Fairfax County resident</a>, give Roof-ER a call to&nbsp;<a title="" href="/inspection" target="set up a time for a free inspection">set up a free storm gutter inspection</a>&nbsp;today.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProtectingGutters;