import React, { Component } from 'react';

// STYLING
import '../../style/components/custom/programInput.scss';

const normalizePhoneInput = (value, previousValue) => {
    if (!value) return value; // return nothing if no value

    const onlyNums = value.replace(/[^\d]/g, ''); // only allows 0-9 inputs

    if (!previousValue || value.length > previousValue.length) {
        // returns: "(xxx)"
        if (onlyNums.length === 3) return `(${onlyNums})`;

        // returns: "(xxx) xxx-"
        if (onlyNums.length === 6) return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}-`

        // returns: "x", "xx", "xxx"
        if (onlyNums.length <= 3) return onlyNums;

        // returns: "(xxx) x", "(xxx) xx",
        if (onlyNums.length <= 6) return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`;

        // returns: "(xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx",

        return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
    }
};


class ProgramInput extends Component {


    constructor(props) {
        super(props);

        this.state = { value: '', focused: false};
        this.inputRef =  React.createRef();
    }


    render() {

        let sizeClass = ` ${this.props.type}`;
        const hideContent = this.state.focused || this.state.value !== '';
        let content = 'NAME';
        switch (this.props.type) {
            case 'firstName':
                content = ['FIRST NAME', <span className="roofDocsRed" key="1"> *</span>];
                break;
            case 'lastName':
                content = ['LAST NAME', <span className="roofDocsRed" key="2"> *</span>];
                break;
            case 'address':
                content = ['ADDRESS', <span className="roofDocsRed" key="3"> *</span>];
                break;
            case 'email':
                content = ['EMAIL', <span className="roofDocsRed" key="4"> *</span>];
                break;
            case 'phone':
                content = ['PHONE', <span className="roofDocsRed" key="5"> *</span>];
                break;
            default:
                break;

        }

        return (
            <div className={"program-input-container" + (sizeClass)}>
                <label onClick={this.titleClicked} style={hideContent ? {'display': 'none'} : {}}>
                    { content }
                </label>
                <input onChange={this.valueChanged}
                       onFocus={() => { this.setState({ focused: true })}}
                       onBlur={() => { this.setState({ focused: false })}}
                       value={this.state.value}
                       ref={this.inputRef}
                       placeholder='' />
            </div>

        );
    }

    titleClicked = () => {

        this.setState({ focused: true });
        this.inputRef.current.focus();

    };

    valueChanged = e => {

        if(this.props.type  === 'phone'){
            let phone = this.state.value;
            const normalized = normalizePhoneInput(e.target.value, phone);
            this.setState({ value: normalized });

        } else  {
            this.setState({value: e.target.value});
        }



        // if (this.props.type === 'address') {
        //     this.googlePlacesSearch( e.target.value );
        // }
    };
}

export default ProgramInput;