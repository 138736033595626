import React, { Component } from 'react';
import { Helmet } from 'react-helmet';


// Styling
import '../../../style/components/blog/articleTemplate.scss';

import BANNER_ICON_UP from "../../../resources/images/misc/plus_icon_up.png";

class RoofingCleanUp extends Component {

    constructor(props) {
        super(props);

        this.state = { };
    }



    render() {

        const search = window.location.search;
        const params = new URLSearchParams(search);
        const ref = params.get('ref') ? params.get('ref') : '0';

        return (
            <div className="blog-template-container">
                <div className="blog-template-banner">
                    <div className="blog-template-text-container">
                        <p className="banner-text-small"> Blog </p>
                        <p className="banner-text-large"> Learn More About Roofing From Roof-ER </p>
                    <Helmet>
                        <title>Key Facts About Roofing Cleanup | Roof-ER</title>
                        <meta name="description" content="Find out more about the roofing cleanup process from the roofing experts at Roof-ER. Schedule your free roof inspection today in Vienna!"/>
                    </Helmet>
                    </div>
                    <img className="banner-icon" src={BANNER_ICON_UP} alt="Banner Icon Up" />
                </div>
                <div className="blog-template-content-container">
                    <div className="blog-template-content">
                        <div className="blog-item-container">
                            <div className="blog-item-info-container">
                                <a href="/blog"><button className="blog-home-button"><span className="arrow-left"> Back to Articles </span> </button></a>
                                <div className="blog-item-info-container-controls">
                                    <a href={`/blog?ref=${ref}&dir=prev`}><button  className="blog-previous-button"><span className="arrow-left"> Previous Article </span> </button></a>
                                    <a href={`/blog?ref=${ref}&dir=next`}><button className="blog-next-button"><span className="arrow-right"> Next Article </span> </button></a>
                                </div>
                                <h1><strong>Everything You Need to Know About Roofing Clean-Up</strong></h1>
                                <p>Your new roof&rsquo;s in place and it looks terrific. You&rsquo;re excited about how wonderful your house looks and you anticipate many years of enjoying your investment.&nbsp;</p>
                                <p>A new roof is a big project. Even after the roof is complete, your journey is not over. The final part of the process is roofing clean-up, and it&rsquo;s a crucial step.&nbsp;It&rsquo;s common for the area around your home to be covered with&nbsp;<a href="/blog/roof-flashing">flashing</a>&nbsp;, felt, shingles, nails and other debris after roofing. You'll want to partner with a roofing company that will handle the clean-up process with care.</p>
                                <h2><strong><strong>The Clean-Up After Roofing Jobs</strong></strong></h2>
                                <p>During clean-up, roofing professionals should pay attention to tidying and to your home&rsquo;s safety. In fact, you&rsquo;ll want to make sure clean-up is specifically included in your roofing contract before you sign. You want the roofers to safely remove any dangerous debris from your property.</p>
                                <p>Quality roofers take care to arrive with a dumpster, put down tarps and leave enough time to carefully clean your property. Your roofer should carefully check for nails, remove old shingles and clean any tar on your walkways.</p>
                                <p>If you see some debris left over, you may be tempted to think it&rsquo;s not a big issue. However, it can be a serious problem. Roofing tar, for example, is very hard to remove if you don&rsquo;t have the right solvents. Trying to do it yourself can result in stains and smears.</p>
                                <p>Nails are also a hazard. If nails get left behind after a roofing job, someone could step on them, causing serious injury or even exposing you to liability. If you run over nails on your lawn with a lawnmower, they can go flying towards you. Nails can also damage your landscaping and lawn.</p>
                                <p>Part of the roofing company&rsquo;s job is to ensure the site is clean and restored to its previous condition. The company should lead you around the property for a look at your completed roof. When they do, be sure to look for any debris before you sign off.</p>
                                <h2><strong><strong>How Do Roofers Clean Up Nails?</strong></strong></h2>
                                <p>Roofers have many tools they can use to avoid leaving nails behind. One of the simplest actions they can take is to lay down tarps around the home before they start the roofing job. Most nails and debris will fall on the tarps, allowing for easier clean-up.</p>
                                <p>Even with a tarp, however, nails can stray and fall outside the tarp area. Roofers can and should use magnetic devices or metal detectors to check for any remaining nails after roofing. While&nbsp;<a href="/docs">professional roofing contractors</a>&nbsp;spend a lot of time and attention doing clean-up right, roofing is major construction work. This is important to keep in mind if you notice some left-over debris or a stray nail in your flower bed.</p>
                                <h2><strong><strong>Finding Professional Roof Repair and Replacement in Vienna, Virginia</strong></strong></h2>
                                <p>If you think you may need a new roof in the Vienna area, Roof-ER can help. Contact us today to&nbsp;<a href="/inspection">set up a free inspection</a>. You can also reach us at&nbsp;<a href="tel:7032393739">703-239-3738</a>&nbsp;if you&rsquo;d like to speak with a member of our team.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default RoofingCleanUp;




