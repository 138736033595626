import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

// Styling
import '../../../style/components/blog/articleTemplate.scss';

import BANNER_ICON_UP from "../../../resources/images/misc/plus_icon_up.png";
import TitleImage from "../../../resources/images/blogs/horizontal-vertical-siding/01-horizontal-vs-vertical-siding[1].jpg";
import Benefits from '../../../resources/images/blogs/horizontal-vertical-siding/02-benefits-of-vertical-siding[1].jpg';
import Inspection
    from "../../../resources/images/blogs/architectural-shingles/02-Get-a-Free-Inspection-With-Roof-ER.jpg";

class HorizontalVerticalSiding extends Component {

    constructor(props) {
        super(props);

        this.state = { };
    }



    render() {

        const search = window.location.search;
        const params = new URLSearchParams(search);
        const ref = params.get('ref') ? params.get('ref') : '0';

        return (
            <div className="blog-template-container">
                <div className="blog-template-banner">
                    <div className="blog-template-text-container">
                        <p className="banner-text-small"> Blog </p>
                        <p className="banner-text-large"> Learn More About Roofing From Roof-ER </p>
                        <Helmet>
                            <title>Horizontal vs. Vertical Siding | Roof-ER</title>
                            <meta name="description" content="Horizontal and vertical siding both have unique benefits. If you are trying to decide which option to choose, we can help with our in-depth guide. Read more."/>
                        </Helmet>
                    </div>
                    <img className="banner-icon" src={BANNER_ICON_UP} alt="Banner Icon Up" />
                </div>
                <div className="blog-template-content-container">
                    <div className="blog-template-content" style={{ width: '100%'}}>
                        <div className="blog-item-container">
                            <div className="blog-item-info-container-full">
                                <a href="/blog"><button className="blog-home-button"><span className="arrow-left"> Back to Articles </span> </button></a>
                            </div>
                            <div className="blog-item-info-container-full">
                                <a href={`/blog?ref=${ref}&dir=prev`}><button  className="blog-previous-button"><span className="arrow-left"> Previous Article </span> </button></a>
                                <a href={`/blog?ref=${ref}&dir=next`}><button className="blog-next-button"><span className="arrow-right"> Next Article </span> </button></a>
                            </div>
                            <div className="blog-item-info-container-full">
                                <h1>Horizontal vs. Vertical Siding</h1>
                            </div>
                            <img className="long-form-image" src={TitleImage} alt="Title" />
                            <div className="blog-item-info-container-full">
                                <p>In addition to being the first thing people see when they look at your house, siding&nbsp;is a representation of you and your family. With that being said, there are a wide range of options when it comes to replacing siding. You can choose between vertical or horizontal siding, as well as the material and the style.</p>
                                <p>In the sections below, we will look at the benefits and types of horizontal versus vertical siding, along with things to consider when selecting new siding for your home.</p>
                                <h2>What Is Horizontal Siding?</h2>
                                <p>Horizontal siding is typically the most common form of siding you will see on houses. This style is also referred to as wrap siding because it is hung from left to right (horizontally) and &ldquo;wraps&rdquo; around the home. Traditionally, wood boards were used as the siding on the exterior of most houses. While you can still use wooden siding on your home, there are several more materials you can choose from now, such as vinyl, engineered wood, aluminum, etc.</p>
                                <h3>Benefits of Horizontal Siding</h3>
                                <p>There are several advantages that come along with having horizontal siding on your home. Some of these benefits include:&nbsp;</p>
                                <ul>
                                    <li><strong>Economical cost:&nbsp;</strong>The most prominent benefit of choosing horizontal siding for your home is the price. This siding is much more affordable than other options. People commonly see this type of siding on homes because of this benefit. Horizontal siding is an excellent option for people who are making upgrades to rental properties, flipping houses or are on a budget for their home improvements.</li>
                                    <li><strong>Easy installation:&nbsp;</strong>Installing horizontal siding is a simple process compared to other housing options. A professional siding business will be able to install the material quickly, cutting back on labor costs and time.</li>
                                    <li><strong>Traditional look:&nbsp;</strong>Since this type of siding is a common option for many homes, it will provide your home with a conventional appearance most homeowners enjoy. This style continues to hold its appeal, and it allows you to create your own look by selecting unique color options and materials.</li>
                                </ul>
                                <h3>Types of Horizontal Siding</h3>
                                <p>This siding comes in many material and color options. Some types of horizontal siding include&nbsp;vinyl,&nbsp;wood, cement and cedar. Typically,&nbsp;vinyl&nbsp;is&nbsp;the most common material that you will see.</p>
                                <p>People typically base the type of siding they choose on the appearance, durability and cost they want. Here are several characteristics of the various types of horizontal siding for your home:</p>
                                <ul>
                                    <li><strong>Vinyl:&nbsp;</strong>This horizontal siding option is a popular choice because you can select from various colors, sizes and shapes. Also, this durable material can last many years in the right conditions. Vinyl siding is simple to install and requires low maintenance throughout its lifetime.</li>
                                    <li><strong>Wood:&nbsp;</strong>Choosing horizontal wood&nbsp;siding can add value to your home because of its long-lasting abilities and unique look. People appreciate the texture and sophisticated aesthetic of wood siding.</li>
                                    <li><strong>Fiber cement:&nbsp;</strong>This horizontal siding option is incredibly durable and popular among homeowners. Fiber cement siding can provide the look of wood siding and requires low maintenance.</li>
                                    <li><strong>Cedar:&nbsp;</strong>Cedar is another type of wood siding, but its high demand makes it have its own category. The range of color, sizing and other elements allow you to customize the look of your home.</li>
                                </ul>
                                <h2>What Is Vertical Siding?</h2>
                                <p>While horizontal siding is installed with the exterior boards parallel to the ground, vertical siding is placed vertically. This option is often thought of as the more modern house siding style. Vertical siding has become more popular on the exterior of homes in recent years, but it was traditionally used on commercial structures and buildings such as barns. The clean lines and wide variety of color and size options may be exactly what you're looking for.</p>
                                <h3>Benefits of Vertical Siding</h3>
                                <p>Just like horizontal siding, this style of siding has several benefits</p>
                            </div>
                            <img className="long-form-image" src={Benefits} alt="Benefits" />
                            <div className="blog-item-info-container-full">
                                <p>Some of these advantages include:</p>
                                <ul>
                                    <li><strong>Unique:&nbsp;</strong>Vertical siding is less common than other options, helping your home stand out among the rest. You'll add to your home's curb appeal and show your own style with this siding.</li>
                                    <li><strong>Trendy:&nbsp;</strong>If you're a homeowner that wants to stay with the new trends to increase resale value, choosing vertical siding is a great option for your home.</li>
                                    <li><strong>Simple to clean:&nbsp;</strong>Vertical siding is easier to clean than horizontal siding because the water travels down the boards smoothly without being trapped inside. This feature helps vertical siding prevent the wood or other material underneath from rotting.</li>
                                    <li><strong>Many options:&nbsp;</strong>This style comes with many design options. You can choose from different plank sizes, various raised accents and multiple materials. Therefore, if you want something other than the more traditional farmhouse vertical siding, there are alternatives for you to choose from.</li>
                                </ul>
                                <h3>Types of Vertical Siding</h3>
                                <p>As we touched on briefly in the section above, there is a wide selection to choose from when you are selecting the style and material of vertical siding for your home. Some of the main types of this siding and the unique features of each include:&nbsp;</p>
                                <ul>
                                    <li><strong>Vinyl:&nbsp;</strong>This material is one of the most popular types as it comes in many different shapes, sizes and colors at a lower cost than most materials. When vinyl is installed vertically, it handles water much better than it does when it is installed horizontally.</li>
                                    <li><strong>Wood:&nbsp;</strong>Wood can last a long time and add more value to your home because of its unique aesthetic. This siding also provides your home with a sophisticated look as it has clean vertical lines. One thing to keep in mind with wood siding is to make sure that you get the materials treated to prevent rotting and insects.</li>
                                    <li><strong>Cedar:&nbsp;</strong>If you have cedar shakes and shingles, choosing siding made of the same material can complete the look. Vertical cedar siding is also easier to install, which will cut down on your labor costs.</li>
                                    <li><strong>Fiber cement:&nbsp;</strong>While fiber cement provides a natural wood appearance, it is far more durable. This material is made from wood pulp, fly ash, cement and water. Fiber cement siding can be pretty complex to install, so hiring contractors may be your best bet.</li>
                                </ul>
                                <h2>Choose the Perfect Siding for Your Home</h2>
                                <p>When&nbsp;<a href="/blog/right-siding">selecting the right siding</a>&nbsp;styles for your home, there are a few things you should consider. For example, choose siding based on your decorating style and the architecture of your home, the vision you have for the design of your house, how much time you want to spend on upkeep, and the amount you would be willing to pay. When selecting siding for your home, it's a good idea to:</p>
                                <ul>
                                    <li>Keep to your own style and preference.</li>
                                    <li>Look for deals when purchasing materials.</li>
                                    <li>Remember the costs of the installation process.</li>
                                    <li>Consider maintenance requirements.</li>
                                </ul>
                                <h2>How Roof-ER Can Help</h2>
                            </div>
                            <a href="/inspection"><img className="long-form-image-clickable" src={Inspection} alt="Inspection" /></a>
                            <div className="blog-item-info-container-full">
                                <p>If you are looking to replace the worn or outdated siding on your home, cabin, rental property, etc. in Fairfax County, Roof-ER is here to help. By committing to&nbsp;<a href="/mission">our mission</a>&nbsp;and our core values of integrity, quality and simplicity, we promise to deliver an experience every homeowner wants when replacing the siding on their home. To receive a free inspection,&nbsp;<a href="/inspection">fill out our form</a>&nbsp;here or&nbsp;<a href="tel:7032393738">give us a call</a>&nbsp;to speak with one of our experts!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default HorizontalVerticalSiding;