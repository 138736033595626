import React, { Component } from 'react';
import DatePicker from "react-date-picker";
import { addDays, format } from "date-fns"
import moment from 'moment';
import axios from 'axios';


// STYLING
import '../../style/components/custom/programDateTimeInput.scss';


class ProgramDateTimeInput extends Component {


    constructor(props) {
        super(props);

        this.state = { selectedDate: null, availableTimes: [], selectedTime: null, repEmail: null, focused: false }
        this.timeSelectionModal = React.createRef();
    }


    render() {


        let selectedTime =  'Selected time';
        if(this.state.selectedTime){
            selectedTime = moment(this.state.selectedTime).format('h:mm A')
        }

        return (
            <div className="program-date-time-input-container" style={this.state.focused ? { zIndex: '9999'} : {}}>
                <label onClick={this.titleClicked} style={this.state.focused || this.state.selectedDate ? {'display': 'none'} : {}}>
                    TIME <span className="roofDocsRed">*</span>
                </label>
                <div className="program-date-picker-container" style={!this.state.focused && !this.state.selectedDate  ? {'display': 'none'} : {}}>
                    <DatePicker className="program-date-picker"
                                calendarType='US' minDate={addDays(new Date(), 1)} maxDate={addDays(new Date(), 30)}
                                onChange={this.dateChanged} value={this.state.selectedDate}
                                isOpen={this.state.focused}

                    />
                    <p className="selected-time-label" style={!this.state.selectedTime ? { display: 'none'} : {}}> { selectedTime } </p>
                </div>
                <ProgramDateTimeSelectionModal parent={this} ref={this.timeSelectionModal} />
            </div>

        );
    }

    dateChanged = date => {
        if(date){
            this.setState({ selectedDate: date, focused: false}, this.loadAvailableTimes)
        } else {
            this.setState({ selectedDate: null, focused: false, availableTimes: [], selectedTime: null})
        }

    };

    loadAvailableTimes() {

        const formattedDate = format(this.state.selectedDate, 'MM/dd/yyyy');


        if(this.props.repEmail){
            const self = this;
            axios.get(`${process.env.REACT_APP_API_URL}web/appointments/${this.props.repEmail}`, {
                params: {
                    date: formattedDate
                },
                headers: {
                    'Access-Control-Allow-Origin': '*'
                }
            }).then(function (response) {

                const availableTimes = response.data.data;
                self.setState({ availableTimes, selectedTime: null});
                self.timeSelectionModal.current.setState({ hidden: false, availableTimes });

            }).catch(function (error) {
                const message = error.message ? error.message : 'Error getting available appointments, please try again';
                console.log(message);
            });
        } else {
            const self = this;
            axios.get(`${process.env.REACT_APP_API_URL}web/program`, {
                params: {
                    date: formattedDate
                },
                headers: {
                    'Access-Control-Allow-Origin': '*'
                }
            }).then(function (response) {

                const availableTimes = response.data.data;
                self.setState({ availableTimes, selectedTime: null});
                self.timeSelectionModal.current.setState({ hidden: false, availableTimes });

            }).catch(function (error) {
                const message = error.message ? error.message : 'Error getting available appointments, please try again';
                console.log(message);
            });
        }



    }

    titleClicked = () => {
        this.setState({ focused: true });
    };

}

export default ProgramDateTimeInput;


class ProgramDateTimeSelectionModal extends Component {

    constructor(props){
        super(props);

        this.state = { hidden: true, availableTimes: [] };

    }

    show() {
        this.setState({ hidden: false });
    }


    closeModal() {

        this.setState({ hidden: true  });
    }

    close = () => {

        if(this.state.availableTimes.length === 0){
            this.props.parent.setState ({ selectedDate: null })
        }


        this.setState({ hidden: true });
    };


    render() {

        const message = this.state.availableTimes.length > 0 ? 'Please select a time for your inspection' : 'No times are available, please select another date';

        return (
            <div className= {"modalContainer" + (this.state.hidden ? ' hiddenModal' : ' animatedModal')} onClick={this.closeModal.bind(this)}>
                <div className="modalContent">
                    <p className="modalHeader">
                        { message }
                    </p>
                    <div style={ this.state.availableTimes.length === 0 ? { display: 'none'} : { display: 'block'}} className="program-schedule-time-container">
                        { this.state.availableTimes.map( time => (
                            <p key={time.date} id={time.date} onClick={this.programTimeClicked} className={this.state.selectedTime === time.date ? 'sel-time' : ''}>
                                {moment(time.date).format('hh:mm a')}
                            </p>
                        ))}
                    </div>
                    <button onClick={this.close} className="errorOkButton"> Close </button>
                </div>
            </div>
        );
    }

    programTimeClicked = e => {

        e.stopPropagation();
        this.props.parent.setState({ selectedTime: e.target.id });
        this.setState({ hidden: true, availableTimes: [] });

    };
}