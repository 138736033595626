import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

// Styling
import '../../../style/components/blog/articleTemplate.scss';

import BANNER_ICON_UP from "../../../resources/images/misc/plus_icon_up.png";

class RoofFlashing extends Component {

    constructor(props) {
        super(props);

        this.state = { };
    }



    render() {

        const search = window.location.search;
        const params = new URLSearchParams(search);
        const ref = params.get('ref') ? params.get('ref') : '0';

        return (
            <div className="blog-template-container">
                <div className="blog-template-banner">
                    <div className="blog-template-text-container">
                        <p className="banner-text-small"> Blog </p>
                        <p className="banner-text-large"> Learn More About Roofing From Roof-ER </p>
                    <Helmet>
                        <title>What Is Roof Flashing? | Roof-ER</title>
                        <meta name="description" content="Learn about what roof flashing and find out about the installation process for your roof. Learn more from the Vienna's roofing experts at Roof-ER!"/>
                    </Helmet>
                    </div>
                    <img className="banner-icon" src={BANNER_ICON_UP} alt="Banner Icon Up" />
                </div>
                <div className="blog-template-content-container">
                    <div className="blog-template-content">
                        <div className="blog-item-container">
                            <div className="blog-item-info-container">
                                <a href="/blog"><button className="blog-home-button"><span className="arrow-left"> Back to Articles </span> </button></a>
                                <div className="blog-item-info-container-controls">
                                    <a href={`/blog?ref=${ref}&dir=prev`}><button  className="blog-previous-button"><span className="arrow-left"> Previous Article </span> </button></a>
                                    <a href={`/blog?ref=${ref}&dir=next`}><button className="blog-next-button"><span className="arrow-right"> Next Article </span> </button></a>
                                </div>
                                <h1>What Is Roof Flashing?</h1>
                                <p>Roof shingles take up a majority of space on the slopes of your roof, but you can't overlook the critical protection that flashing provides. Many&nbsp;<a href="/services/roofreplacement">roof installations</a>&nbsp;such as your vents, chimney and&nbsp;<a href="/services/gutters">gutters</a>&nbsp;should be guarded against seeping moisture.</p>
                                <h2>Is Roof Flashing Necessary?</h2>
                                <p>How important is roof flashing to the overall health of your home? Flashing redirects rainwater and melting snow from slipping underneath the shingles or find it's way into underlying "breaks" in your roofs underlayment, such as the space between plywood decking or where a one-story portion of your roof meets a 2-story portion. Flashing is as necessary as the shingles themselves in preventing water damage, such as leaks and mold,&nbsp;to the structure of your roof. It also stands up to other natural forces such as wind and&nbsp;<a href="/blog/hail-damage">hail</a>.</p>
                                <h2>Roof Flashing Materials</h2>
                                <p>The most common roof flashing materials include malleable, rust-resistant metals. When installed correctly, the chosen roof flashing will last as long as the shingles and underlayment. Some homeowners prefer flashing that complements their home's exterior. Ultimately, you must carefully select the right flashing material to avoid&nbsp;adverse reactions when touching building products such as cement, mortar and treated wood.</p>
                                <p>Some types of materials that&nbsp;<a href="/docs">contractors</a>&nbsp;often use for roof flashing include:</p>
                                <ul>
                                    <li>Aluminum.</li>
                                    <li>Galvanized/stainless steel.</li>
                                    <li>Lead.</li>
                                    <li>Copper.</li>
                                    <li>Zinc.</li>
                                    <li>Rubber flashing tape.</li>
                                </ul>
                                <h2>Roof Flashing Installation</h2>
                                <p>Flashing should be replaced or reinstalled as soon as it is removed to avoid exposing the shingles and underlayment to the elements. Roof flashing is often installed using nails, fittings or a strong adhesive.</p>
                                <h3>Where Is Roof Flashing Installed?&nbsp;</h3>
                                <p>Flashing should be attached wherever the roof meets the home's walls, a fixture is installed or there are changes in direction or slope. Common types of roof flashing and their locations include:</p>
                                <ul>
                                    <li><strong>Drip edges.&nbsp;</strong>Seals the bottom of the roof and prevents the fascia from rotting</li>
                                    <li><strong>Headwall flashing.&nbsp;</strong>Protects the juncture where the wall and slope of the roof meet</li>
                                    <li><strong>Step flashing.&nbsp;</strong>Installs along the wall on a roof slope to divert water, interweaving with the shingles</li>
                                    <li><strong>Valley flashing.&nbsp;</strong>Covers where two slopes of the roof join together</li>
                                    <li><strong>Kick-out flashing.</strong>&nbsp;Installs where a sloped roof plane meets a vertical side-wall in order to redirect water into the gutters and away from the sidewall</li>
                                </ul>
                                <p>Other locations include chimney flashing to prevent seepage through the bricks, skylight flashing to protect a window and specially crafted vent flashing to fit over the base of an airflow opening.</p>
                                <h3>Does Flashing Go Over Shingles?</h3>
                                <p>The answer often depends on the type of flashing. For example, headwall flashing overlays the roofing materials, while the bases of step flashing, drip edges and kick-out flashing are often underneath the shingles.</p>
                                <p>On vertical surfaces, contractors might use counterflashing to cover the base flashing.</p>
                                <h2>Free, Professional Roofing Inspections From Roof-ER</h2>
                                <p>A flashing inspection is critical in a comprehensive roof inspection, and&nbsp;<a href="/docs">our team of experienced roofing technicians</a>&nbsp;will provide a thorough evaluation at no cost to you. We work swiftly and efficiently, so no further source of harm to your roof goes unchecked.&nbsp;</p>
                                <p>If you suspect your home's exterior in&nbsp;<a href="/areas/vienna">Vienna, Virginia</a>&nbsp;and&nbsp;<a href="/areas/fairfax">Fairfax County</a>&nbsp;has missing or damaged flashing,&nbsp;<a href="/inspection">contact Roof-ER online</a>&nbsp;or call us at 703-239-3738 to set up a free inspection.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default RoofFlashing;