import React, { Component } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { geocodeByPlaceId } from 'react-places-autocomplete';

// STYLING
import '../../style/components/custom/imageInput.scss';

// IMAGES
import Address from '../../resources/images/inputIcons/icon_sm_address_gry.png';



class AddressInput extends Component {


    constructor(props) {
        super(props);

        this.state = { address: ''}
        // this.autocomplete = null;
    }

    componentDidMount() {
        // this.autocomplete = new google.maps.places.Autocomplete(document.getElementById('autocomplete'), {})

        // this.autocomplete.addListener("place_changed", this.handlePlaceSelect)
    }

    handleChange = e => {

        this.setState({ address: e });
    };


    handleSelect = async (address, placeId) => {



        const [place] = await geocodeByPlaceId(placeId);


        const { long_name: streetNumber = '' } = place.address_components.find(c => c.types.includes('street_number')) || {};
        const { short_name: addressLine1 = '' } = place.address_components.find(c => c.types.includes('route')) || {};
        const { long_name: city = '' } = place.address_components.find(c => c.types.includes('locality')) || {};
        const { short_name: state = '' } = place.address_components.find(c => c.types.includes('administrative_area_level_1')) || {};
        const { long_name: zipCode = '' } = place.address_components.find(c => c.types.includes('postal_code')) || {};

        const selected = {
            streetNumber, addressLine1, city, state, zipCode
        };

        let value = '';
        if(selected.streetNumber){
            value = selected.streetNumber
        }
        if(selected.addressLine1){
            value = value === '' ? selected.addressLine1 : `${value} ${selected.addressLine1}`
        }
        if(this.props.quick){
            value = value === '' ? selected.addressLine1 : `${value}, ${selected.city}, ${selected.state} ${selected.zipCode}`
        }

        this.setState({ address: value });
        this.props.parent.addressSelected ( selected )

        // console.log("postalCode",postalCode);
    };

    // handleSelect = e => {
    //
    //     console.log(e);
    //     this.setState({ address: e });
    // };

    render() {


        return (
            <div className="image-input-container">
                <img style={this.props.type === 'roofAge' ? {display: 'none'} : {}} className='address'
                     src={Address} alt="Input Icon"/>
                <PlacesAutocomplete
                    value={this.state.address}
                    onChange={this.handleChange}
                    onSelect={this.handleSelect}
                    shouldFetchSuggestions={this.state.address.length > 2}
                >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div className="places-search-container">
                            <input
                                autoComplete="no"
                                {...getInputProps({
                                    placeholder: 'Enter your address',
                                })}
                            />
                            <div className="autocomplete-dropdown-container">
                                {/*{loading && <div>Loading...</div>}*/}
                                {suggestions.map( (suggestion, index) => {
                                    const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                        ? { backgroundColor: '#dadada', cursor: 'pointer' }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                    return (
                                        <div key={index}
                                            {...getSuggestionItemProps(suggestion, {
                                                className,
                                                style,
                                            })}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </PlacesAutocomplete>
            </div>

        );
    }
}

export default AddressInput;