import React, { Component } from 'react';


// Styling
import '../style/components/inspection.scss';
import '../style/components/freeEstimate.css';
import '../style/components/modal.css'


import Products from "./custom/products";
import GoogleReviews from "./custom/googleReviews";
import WorkBanner from "./custom/workBanner";


import FordBarsi from '../resources/images/employees/FordBarsi.png'



class DefectiveShinglesProgramThankYou extends Component {

    constructor(props) {
        super(props);

        this.state = { }
    }

    render() {
        return (
            <div className="inspection-page-container">

                <p className="thankYouMessage">
                    Thank you for scheduling your free inspection! I look forward to meeting you and assisting with this process
                </p>

                <img className="thankYouRepImage" src={FordBarsi} alt="Fort Barsi" />

                <WorkBanner/>
                <GoogleReviews/>
                <Products/>

            </div>
        );
    }




}

export default DefectiveShinglesProgramThankYou;



