import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

// Styling
import '../../../style/components/blog/articleTemplate.scss';

import BANNER_ICON_UP from "../../../resources/images/misc/plus_icon_up.png";
import IWS from "../../../resources/images/misc/IWS.jpg";

const ROOF_PITCH = [
    '1/12',
    '2/12',
    '3/12',
    '4/12',
    '5/12',
    '6/12',
    '7/12',
    '8/12',
    '9/12',
    '10/12',
    '11/12',
    '12/12 or Greater'
];

class IceWaterShield extends Component {

    constructor(props) {
        super(props);

        this.state = { wallDepth: '6"', iwsFactor:  '', courses: '' };
        this.roofPitch = React.createRef();
        this.overhangMeasurement = React.createRef();
    }



    render() {

        const search = window.location.search;
        const params = new URLSearchParams(search);
        const ref = params.get('ref') ? params.get('ref') : '0';

        return (
            <div className="blog-template-container">
                <div className="blog-template-banner">
                    <div className="blog-template-text-container">
                        <p className="banner-text-small"> Blog </p>
                        <p className="banner-text-large"> Learn More About Roofing From Roof-ER </p>
                        <Helmet>
                            <title>What You Need To Know About Ice & Water Shield | Roof-ER</title>
                            <meta name="description" content="Learn about the benefits of an ice shield for roofs and discover how ice and show shields prevent ice dams. Learn more from VA's roofing experts, Roof-ER!"/>
                        </Helmet>
                    </div>
                    <img className="banner-icon" src={BANNER_ICON_UP} alt="Banner Icon Up" />
                </div>
                <div className="blog-template-content-container">
                    <div className="blog-template-content">
                        <div className="blog-item-container">
                            <div className="blog-item-info-container">
                                <a href="/blog"><button className="blog-home-button"><span className="arrow-left"> Back to Articles </span> </button></a>
                                <div className="blog-item-info-container-controls">
                                    <a href={`/blog?ref=${ref}&dir=prev`}><button  className="blog-previous-button"><span className="arrow-left"> Previous Article </span> </button></a>
                                    <a href={`/blog?ref=${ref}&dir=next`}><button className="blog-next-button"><span className="arrow-right"> Next Article </span> </button></a>
                                </div>
                                <h1>Everything You Need To Know About Roof Ice &amp; Water Shields</h1>
                                <p>Ice &amp; water shield (IWS) or barrier (IWB) has been one of the most transformative leak-protection products to hit the roofing industry in the last thirty years. It is a polymer-modified bitumen material with special characteristics that make it a necessary component of every effective roofing system.</p>
                                <h2>What is ice &amp; water shield used for?</h2>
                                <p>Ice &amp; water shield is utilized for the areas of a roof that are most susceptible to water intrusion, be that wind-driven rain or pooled water resulting from an ice-dam. What makes this material unique is its ability to create a watertight-seal around any fasteners that penetrate it. That way, when the remaining layers of roofing are installed, e.g. starter strip, roofing shingles, etc., the nails are able to go through the underlying ice &amp; water barrier without creating an avenue for water to get beneath the roofing system and into the roof decking or the attic.</p>
                                <p>Given this feature, professional roofing companies ensure ice &amp; water shield is used on every job. Is ice and water shield necessary? In many states and jurisdictions, an ice &amp; water shield is actually required by building code regulations.</p>
                                <h2>When and where do you nail ice &amp; water shield for installation?</h2>
                                <p>Ice &amp; water roofing material is most often required to be installed in colder/harsh-weather climates. One of the most common causes of roof leaks is from water pooling along a home's eaves behind what are called ice dams. Ice dams can form when a roof has improper ventilation, causing snow to melt at the top of the roof where it refreezes into ice at the gutter line. From there, water will pool behind the ice dam and will seek the path of least resistance. That usually means finding its way underneath the shingle sealant and into the next layer of the roofing system. If IWS is not used as the underlying layer, then the previous nail-holes are prime locations for water to compromise the roofing system and get into the roof sheathing and/or attic space.</p>
                                <p>It is also standard to install ice shielf roof material along all roof valleys. This is where two roof slopes meet to form a concave joint. This area is subject to high-water and debris flow during tough weather, as both roof slopes direct water towards the valley. Thus, if the seal between the shingles on each slope fails, the roof must rely on the underlying ice &amp; water shield to do its job in protecting against leaks. Other places that knowledgeable&nbsp;<a href="/docs">roofers</a>&nbsp;will install this kind of underlayment are around pipe jacks, behind&nbsp;<a href="/roof-flashing">metal flashing</a>, or anywhere else there is a "break" in the otherwise seamless roof slopes.</p>
                                <h2>Is there anywhere an ice and water roof shield should not be installed?</h2>
                                <p>After touting the many benefits that IWS offers, many customers ask why it shouldn't be installed along the entire roof decking. This is a great question and the answer is due to something called permeability. Moisture can build up in your attic space at times and get into the roof decking where it needs to escape. IWS is not a permeable material, meaning that moisture would remain trapped and could create rot in the wood decking. Instead, there are alternative <a href="/blog/roofing-felt">roofing underlayment</a> materials that do provide permeability and are to be used in the areas less susceptible to roof leaks</p>
                                <h2>How much Ice &amp; water shield should be installed?</h2>
                                <p>Most residential building codes or manufacturer's installation instructions require an ice &amp; water barrier to be installed 24" inside the "warm wall" of the building. The image below provides a visual representation of how this rule is to be interpreted:</p>
                                <img className="iws-image" src={IWS} alt="Ice & Water Shield" />
                                <p>Roof water and ice shield is manufactured in 3 ft.-wide rolls, so most homes require either one or two "rows" of the material along their eaves plus one row in their valleys, depending on the length of the soffit overhang and the pitch of the roof. Basic math can determine whether one or two courses are required to be installed along the eaves of a home. Try our ice & water roof shield calculator below to see how many water and ice shield roof courses your roof should have:</p>


                                <div className="iws-calculator-container">
                                    {/* Overhang */}
                                    <div className="iws-calculator-field-container">
                                        <label> Overhang Measurement (inches)</label>
                                        <input ref={this.overhangMeasurement} onKeyPress={this.keyPressed}  onBlur={this.overhangChanged} type="number" step="1" />
                                    </div>

                                    {/* Wall Depth */}
                                    <div className="iws-calculator-field-container">
                                        <label> Wall Depth </label>
                                        <input value={this.state.wallDepth} disabled />
                                    </div>

                                    <div className="iws-calculator-field-container">
                                        <label> Predominate Roof Pitch </label>
                                        <select onChange={this.roofPitchChanged} ref={this.roofPitch} defaultValue='1/12'>
                                            { ROOF_PITCH.map( (opt, index) => (
                                                <option key={index} value={opt}> { opt } </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="iws-calculator-field-container">
                                        <label> IWS Factor </label>
                                        <input  value={this.state.iwsFactor} disabled/>
                                    </div>

                                    <div className="iws-calculator-field-container">
                                        <label> Courses </label>
                                        <input value={this.state.courses}  disabled />
                                    </div>

                                </div>




                                {/*<ul>*/}
                                {/*    <li>Overhang measurement (inches):</li>*/}
                                {/*    <li>Wall depth: 6" - hardcoded</li>*/}
                                {/*    <li>Roof pitch: 1/12 - 12/12 or greater</li>*/}
                                {/*    <li>IWS factor: shows what the factor becomes</li>*/}
                                {/*    <li>Courses: anything &gt;3 displays as two courses</li>*/}
                                {/*</ul>*/}
                                <p>Less knowledgeable contractors will often simply install one course of Ice &amp; water shield when two courses are actually required, given that they misinterpret the 24" rule. If you receive a quote from a roofer offering to install only one course of IWS, it will be in your best interest to confirm their calculations are correct.</p>
                                <p>If you are in the market for a new roof or are noticing a leak around one of the areas described above, give us a call at <a href="tel:7032393738"> (703) 239-3738 </a> or&nbsp;<a href="/inspection"> schedule an inspection</a>&nbsp; with one of our trained and licensed&nbsp;<a href="/docs">Roof Docs!</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    keyPressed = e => {

        if(e.key === 'Enter') {
            this.overhangMeasurement.current.blur();
        }
    };

    overhangChanged = e => {


        const overhang = parseInt(e.target.value);
        const roofPitch = this.roofPitch.current.value;
        if(!isNaN(overhang)){
            const horizontal = overhang + 6 + 24;
            const pitchNum = parseInt(roofPitch.split('/')[0]);
            const vertical = (pitchNum/12) *  horizontal;
            const iwsFactorCalc = Math.sqrt( ((horizontal * horizontal) + (vertical * vertical))) / 12;
            let courses = '1 course';
            if(iwsFactorCalc > 3){
                courses  = '2 courses'
            }
            const iwsFactor = iwsFactorCalc.toFixed(2);
            this.setState({ iwsFactor, courses })
        }


    };

    roofPitchChanged = e => {

        const roofPitch = e.target.value;
        const overhang = parseInt(this.overhangMeasurement.current.value);

        if(!isNaN(overhang)){
            const horizontal = overhang + 6 + 24;
            const pitchNum = parseInt(roofPitch.split('/')[0]);
            const vertical = (pitchNum/12) *  horizontal;
            const iwsFactorCalc = Math.sqrt( ((horizontal * horizontal) + (vertical * vertical))) / 12;

            let courses = '1 course';
            if(iwsFactorCalc > 3){
                courses  = '2 courses'
            }
            const iwsFactor = iwsFactorCalc.toFixed(2);
            this.setState({ iwsFactor, courses })
        }
    };
}

export default IceWaterShield;