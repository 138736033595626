import React, { Component } from 'react';


// STYLING
import '../../style/components/custom/freeQuoteBanner.scss';

class FreeQuoteBannerRed extends Component {


    render() {
        return (
            <div>
                <div className="white-padding" />
                <div className="free-quote-container-inverse">

                    <p className="free-quote-text-inverse"> CONTACT US NOW </p>
                    <a href="/inspection"><button className="free-quote-button-inverse"> Give Me A Free Inspection </button></a>

                </div>
            </div>
        );
    }
}

export default FreeQuoteBannerRed;