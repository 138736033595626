import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

// Styling
import '../../../style/components/blog/articleTemplate.scss';


import BANNER_ICON_UP from "../../../resources/images/misc/plus_icon_up.png";

class RightSiding extends Component {

    constructor(props) {
        super(props);

        this.state = { };
    }


    render() {

        const search = window.location.search;
        const params = new URLSearchParams(search);
        const ref = params.get('ref') ? params.get('ref') : '0';

        return (
            <div className="blog-template-container">
                <div className="blog-template-banner">
                    <div className="blog-template-text-container">
                        <p className="banner-text-small"> Blog </p>
                        <p className="banner-text-large"> Learn More About Roofing From Roof-ER </p>
                <Helmet>
                        <title>Choosing the Right Vinyl Siding for Your Home | Roof-ER</title>
                        <meta name="description" content="Learn how to choose the right vinyl siding to improve the look of your home. Roof-ER offers the top siding replacement services in Fairfax County. Contact us!"/>
                </Helmet>
                    </div>
                    <img className="banner-icon" src={BANNER_ICON_UP} alt="Banner Icon Up" />
                </div>
                <div className="blog-template-content-container">
                    <div className="blog-template-content">
                        <div className="blog-item-container">
                            <div className="blog-item-info-container">
                                <a href="/blog"><button className="blog-home-button"><span className="arrow-left"> Back to Articles </span> </button></a>
                                <div className="blog-item-info-container-controls">
                                    <a href={`/blog?ref=${ref}&dir=prev`}><button  className="blog-previous-button"><span className="arrow-left"> Previous Article </span> </button></a>
                                    <a href={`/blog?ref=${ref}&dir=next`}><button className="blog-next-button"><span className="arrow-right"> Next Article </span> </button></a>
                                </div>
                                <h1>Choosing the Right Vinyl Siding for Your Home</h1>
                                <p>Vinyl siding remains one of the most popular materials for protecting homes. In addition to being practical, vinyl siding is also highly attractive and can spruce up the curb appeal of any property.</p>
                                <p className="main-text">As a homeowner, you'll want to understand the key advantages of&nbsp;<a title="" href="/services/sidingreplacement" target="choosing vinyl siding as a replacement">choosing vinyl siding as a replacement</a>&nbsp;for other siding types. You'll also need to consider what color of vinyl siding replacement will add character and aesthetic appeal to your house.</p>
                                <h2>The Benefits of Vinyl Siding</h2>
                                <p>Why are so many people switching to vinyl siding? The benefits include that it's:</p>
                                <ul>
                                    <li><strong>Durable:</strong>&nbsp;When installed by a team of professionals, high-quality vinyl siding can last anywhere from half a century to a full century. That's a serious return on your initial investment.</li>
                                    <li><strong>Cost-effective:&nbsp;</strong>Vinyl siding is a long-lasting and affordable choice for covering the outside of your residence. Best of all, it may even boost the resale value of your house.</li>
                                    <li><strong>Ability to mimic the look of other materials:&nbsp;</strong>Vinyl siding manufacturers have figured out how to echo the look of everything from natural wood to carved stone.</li>
                                    <li><strong>Energy efficiency:&nbsp;</strong>Want to lower your utility bills? Vinyl siding can help you maintain a comfortable home without increasing the amount you spend on heating and cooling.</li>
                                    <li><strong>Easy to maintain:&nbsp;</strong>It takes very little effort and minimal maintenance to keep your vinyl siding looking like new for the lifetime of your property.</li>
                                    <li><strong>Free of potential issues like rust and dents:&nbsp;</strong>A huge benefit of vinyl siding over aluminum and metal options is that it's rust-free. Additionally, it won't dent like its aluminum counterpart.</li>
                                    <li><strong>Doesn't attract insects:&nbsp;</strong>Vinyl siding doesn't provide suitable conditions for insects to take over.&nbsp;</li>
                                    <li><strong>Customizable thanks to an array of colors:&nbsp;</strong>You would be hard-pressed to find any kind of siding material that comes in as many colors and textures as vinyl. No matter your preferred architectural style or design, you can be certain to find a vinyl siding for your needs.</li>
                                </ul>
                                <p>In addition to these advantages, vinyl siding frequently comes with generous manufacturer's warranties. This adds another level of peace of mind to your decision.</p>
                                <h2>How to Find a Siding Color to Match Your Home</h2>
                                <p>After choosing vinyl siding instead of alternatives such as fiber cement, engineered wood or stone veneer siding, you'll want to think about the right color for your house.</p>
                                <p>In addition to working with&nbsp;<a href="/right-contractor">your siding installation contractor</a>&nbsp;, while you're exploring the many palette possibilities vinyl siding offers, remember to:</p>
                                <ul>
                                    <li><strong>Determine the color of your roof:&nbsp;</strong>Depending on your roof's hue, you may want to stick with complementary colors. For instance, to highlight a reddish roof, you might want to choose white, cream or eggshell-colored vinyl siding. On the other hand, if your roof is neutral, you can add a bolder colored vinyl siding to give your house personality.</li>
                                    <li><strong>Test the grays and tans:&nbsp;</strong>Although it might seem as if gray and beige tones would be boring on a house, they can imbue a property with classical good looks and a sense of sophistication. Deeper grays exude traditionalism, whereas lighter grays deliver freshness and modernity. Warm tans and browns make a home seem inviting, whereas sable browns convey earthiness.</li>
                                    <li><strong>Consider the blues:&nbsp;</strong>You may have noticed that a lot of homes boast blue siding. Blue is a safe choice, especially when you want to breathe new life into an older property.</li>
                                </ul>
                                <p>No matter what color you pick for your vinyl siding, be certain to choose the right installer for the project. If you're in need of siding replacement in Tysons, VA,&nbsp;<a title="" href="/inspection" target="contact The Roof Docs online">contact Roof-ER online</a>&nbsp;or call&nbsp;703-239-3738.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default RightSiding;