import React, { Component } from 'react';
// import {Helmet} from "react-helmet";
// import GoogleReviews from "./custom/googleReviews";
// import Slider from 'infinite-react-carousel';
// import ImageInput from "./custom/imageInput";
// import AddressInput from "./custom/addressInput";
import axios from 'axios';


// Styling
import '../style/components/defectiveShinglesProgram.scss';
import '../style/components/mission.scss';

// import BANNER_ICON_UP from "../resources/images/misc/plus_icon_up.png";
// import StormDamageMap from '../resources/images/defectiveShingles/StormDamage_2019_Present.jpeg';

import Horizon1 from '../resources/images/defectiveShingles/Horizon1.png'
import HorizonG from '../resources/images/defectiveShingles/Horizong.png'

// // Check Images
import ErieCheck from '../resources/images/defectiveShingles/ErieCheck.png';
import NationwideCheck from '../resources/images/defectiveShingles/NationwideCheck.png';
import StateFarmCheck from '../resources/images/defectiveShingles/StateFarmCheck.png';
import TravelersCheck from '../resources/images/defectiveShingles/TravelersCheck.png';
import USAACheck from '../resources/images/defectiveShingles/USAACheck.png';
import LibertyMutualCheck from '../resources/images/defectiveShingles/LibertyMutualCheck.png';
import FordBarsi from '../resources/images/employees/FordBarsi.png';
import Logo from '../resources/images/logos/Alt_Logo.png'

import StormDamage from '../resources/images/defectiveShingles/StormDamage_2019_Present.jpeg';
import ProgramInput from "./custom/programInput";
import ProgramDateTimeInput from "./custom/programDateTimeInput";
import ProgramAddressInput from "./custom/programAddressInput";

const CHECKS = [ ErieCheck, NationwideCheck, StateFarmCheck, TravelersCheck, USAACheck, LibertyMutualCheck ];


class DefectiveShinglesProgram extends Component {

    constructor(props) {
        super(props);

        this.state = { errors: [], selectedAddress: null, checkIndex: 0, intervalId: null};
        this.firstNameRef = React.createRef();
        this.lastNameRef = React.createRef();
        this.addressRef = React.createRef();
        this.phoneRef = React.createRef();
        this.emailRef = React.createRef();
        this.dateRef = React.createRef();
        this.inspectionRef = React.createRef();
        this.timer = this.timer.bind(this)
    }

    componentDidMount() {
        const intervalId = setInterval(this.timer, 2000);
        this.setState({intervalId: intervalId});
    }

    componentWillUnmount() {
        // use intervalId from the state to clear the interval
        clearInterval(this.state.intervalId);
    }

    timer() {
        // setState method is used to update the state
        const checkIndex = this.state.checkIndex === CHECKS.length - 1 ? 0 : this.state.checkIndex + 1;
        this.setState({ checkIndex });
    }


    render() {

        // const settings =  {
        //     autoplay: true,
        //     autoplaySpeed: 4000,
        //     arrows: false,
        //     className: 'autoscroll-container',
        //     dots: true,
        //     duration: 50,
        //     pauseOnHover: false,
        //     // shift: 100,
        //     centerMode: true,
        // };
        //
        // const shingleSettings =  {
        //     autoplay: true,
        //     autoplaySpeed: 4000,
        //     arrows: false,
        //     className: 'autoscroll-container-shingle',
        //     duration: 100,
        //     pauseOnHover: false,
        //     centerMode: true,
        // };



        return (
            <div className="defective-shingles-container">
                <a href="/"><img className="logo-home-link" alt="Logo"  src={Logo}  /></a>
                <div className="program-name-banner">
                    <div className="banner-text-content">
                        <p> Defective Shingles Program </p>
                    </div>

                    <div className="banner-left-content">
                        <img src={Horizon1} alt="Horizon 1" />

                    </div>
                    <div className="banner-right-content">
                        <img src={HorizonG} alt="Horizon G" />

                    </div>
                    <button onClick={() => {this.inspectionRef.current.scrollIntoView()}}> Schedule Your Free Inspection </button>
                    {/*<div className="mission-banner-text-container">*/}
                    {/*    <Helmet>*/}
                    {/*        <title>Defective Shingles Program | Roof-ER</title>*/}
                    {/*        /!*<meta name="description" content="The Roof Docs are a Fairfax County roof contractor committed to quality craftsmanship. Learn about our mission statement. Sign up for a free roof inspection! " />*!/*/}
                    {/*    </Helmet>*/}
                    {/*    <p className="banner-text-small"> Free Inspection </p>*/}
                    {/*    <p className="banner-text-large"> Defective Shingles Program  </p>*/}
                    {/*</div>*/}
                    {/*<img className="banner-icon" src={BANNER_ICON_UP} alt="Banner Icon Up" />*/}
                </div>
                <div className="defective-shingles-content">
                    <div className="learn-more">
                        <p className="main-text">Thanks for wanting to learn more about our <span className="roofDocsRed"> Free Inspection!</span></p>
                        <p className="sub-text">
                            As I mentioned in the letter you received, your shingles were part of a class action lawsuit in 2008 which the manufacturer lost due to premature
                            deterioration of the shingles, resulting in the "spiderweb" effect shown in the photos above.
                        </p>
                    </div>
                    <div className="storm-activity">
                        <p className="start-text"> Unfortunately, it is <span className="bold"> too late to collect </span> any funds from this settlement. </p>
                        <p className="main-text">However, most homeowners are still <span className="roofDocsRed">able to take advantage of a unique opportunity </span>
                            through their homeowner's insurance
                        </p>
                        <p className="sub-text"> Over the last couple of years, the Northern Virginia and Maryland regions have seen unprecedented storm activity resulting in hail and severe winds.
                            The map below shows areas that have been impacted since late 2019. </p>

                    </div>
                    <div className="perform-inspection">
                        <img src={StormDamage} alt="Storm Damage" />

                        <div className="content-blocks-container">
                            <div className="left">
                                <p> These storms have caused damage to many of the roofs in the  area, significantly
                                    reducing the useful life of one of the biggest investments on your home.  As a result,
                                    insurance companies are on the hook to cover the cost of replacing your roof if it
                                    has sustained storm damage. <br /> <br />
                                    This means you could be eligible to <span className="bold"> get a full replacement
                                        for only the cost of your deductible! </span>
                                </p>
                            </div>
                            <div className="right">
                                <p> The reason I've reached out to you specifically is because your shingle is
                                    discontinued. <br /> <br />
                                    If the is ANY evidence of storm damage, your homeowner's insurance is
                                    obligated to replace it because it is impossible to do a simple repair.
                                    It's important to mention that most insurance companies will not simply
                                    agree to replace your roof without an expert on your side who knows how to
                                    keep them accountable. <span className="bold"> That's where I step in </span>
                                </p>

                            </div>
                        </div>

                        <p className="main-text">I will perform a <span className="roofDocsRed">Free Inspection </span>
                            for your property to see if there is any evidence of <span className="program-gray">storm damage, latent or apparent.</span>
                        </p>

                        <p className="sub-text"> If I find damage, I will review my findings with you and
                            can assist you with filing the claim with your insurance company.  From there,
                            I will meet with your insurance adjuster during his inspection to make sure
                            everything is properly documented and accounted for.  Once they've agreed to
                            cover the cost of replacing your roof, you'll receive a check in the mail
                            and our installation team will complete your project in a timely fashion.
                        </p>
                        <button onClick={() => {this.inspectionRef.current.scrollIntoView()}}> Schedule Your Free Inspection </button>

                    </div>

                    <div className="opportunity-content">
                        <img className="check-image" src={CHECKS[this.state.checkIndex]} alt="Damage Check" />

                        <div className="opportunity-savings">
                            <div className="content">
                                <p className="main-text">
                                    By taking advantage of this opportunity, our customers
                                    <span className="roofDocsRed"> save $15,855.00 on average, </span> <span className="program-gray">when compared to
                                    paying for a new roof out of their own pocket!</span>
                                </p>
                                <p className="sub-text">
                                    I understand this opportunity may sound too good to be true.  That's why my inspection
                                    is 100% free and without obligation and I'm happy to answer any questions you have
                                    before scheduling an inspection.
                                </p>
                            </div>
                            <div className="rep">
                                <img src={FordBarsi} alt="Ford Barsi" />
                            </div>
                        </div>
                    </div>

                    <div className="inspection-request" ref={this.inspectionRef}>
                        <button className="call-text-button"> Call or Text (703) 239-3204 </button>
                        <p className="sub-text">
                            If you're ready to schedule an inspection and would prefer to simply schedule online, please
                            use the tool below to find a time that's convenient for you
                        </p>
                        <p className="main-text">
                            I look forward to assisting you with this project!
                        </p>

                        <div className="request-form" id="inspection">
                            <ProgramInput ref={this.firstNameRef} type="firstName" />
                            <ProgramInput ref={this.lastNameRef} type="lastName" />
                            <ProgramAddressInput parent={this} ref={this.addressRef} />
                            <ProgramInput ref={this.emailRef} type="email" />
                            <ProgramInput ref={this.phoneRef} type="phone" />
                            <ProgramDateTimeInput ref={this.dateRef} />
                        </div>
                        <button onClick={this.submitClicked} className="schedule-button"> Schedule your free inspection </button>
                        <p className="required-text"> Fields marked with <span className="roofDocsRed">*</span> are mandatory </p>
                    </div>


                        {/*<p className="content-title"> Defective Shingles  Program </p>*/}
                        {/*<p className="content-info"> Thanks for wanting to learn more about our free inspection! As I mentioned in the letter you received, your shingles*/}
                        {/*    were part of a class-action lawsuit in 2008 which the manufacturer lost due to premature deterioration of the shingles, resulting in*/}
                        {/*    the "spiderweb" effect shown in the photos below:*/}
                        {/*</p>*/}
                        {/*/!* Shingle Images Autoscroll *!/*/}
                        {/*<div>*/}
                        {/*    <Slider { ...shingleSettings }>*/}

                        {/*        <div>*/}
                        {/*            <img src={Horizon1} alt="Horizon1" />*/}
                        {/*        </div>*/}
                        {/*        <div>*/}
                        {/*            <img src={Horizong} alt="Horizong" />*/}
                        {/*        </div>*/}

                        {/*    </Slider>*/}
                        {/*</div>*/}

                        {/*<p className="content-info"> Unfortunately, it is too late to collect any funds from the settlement. However, most homeowners are still able to*/}
                        {/*    take advantage of a unique opportunity through their homeowner's insurance. Over the last couple of years, the Northern Virginia and Maryland*/}
                        {/*    regions have seen unprecedented storm activity resulting in hail and severe winds. The map below shows areas that have been impacted since late 2019.*/}
                        {/*</p>*/}

                        {/*/!* Hail Map *!/*/}
                        {/*<img src={StormDamageMap} alt="Storm Damage - 2019 to Present" className="storm-damage-image" />*/}

                        {/*<p className="content-info">These storms have caused damage to many of the roofs in the area, significantly reducing the useful life of one of the*/}
                        {/*    biggest investments on your home. As a result, insurance companies are on the hook to cover the cost of replacing your roof if it has sustained*/}
                        {/*    storm damage. This means you could be eligible to get a full roof replacement for <span className="bold"> only the cost of your deductible!</span>*/}
                        {/*</p>*/}

                        {/*<p className="content-info">The reason I've reach out to you specifically is because your shingle is discontinued. If there is ANY evidence of storm damage,*/}
                        {/*    your homeowner's insurance is obligated to replace it because it is impossible to do a simple repair. It's important to mention that most insurance*/}
                        {/*    companies will not simply agree to replace your roof without an expert on your side who knows how to keep them accountable. That's where I step in.*/}
                        {/*</p>*/}

                        {/*<p className="content-info">I will perform a free inspection for your property to see if there is any evidence of storm damage, latent or apparent. If I*/}
                        {/*    find damage, I will review my findings with you and can assist you with filing the claim with your insurance company. From there, I will meet with*/}
                        {/*    your insurance adjuster during his inspection to make sure everything is properly documented and accounted for. Once they've agreed to cover*/}
                        {/*    the cost of replacing your roof, you'll receive a check in the mail and our installation team will complete your project in a timely fashion.*/}
                        {/*</p>*/}


                        {/*/!* Check Image Autoscroll *!/*/}
                        {/*<div className="check-autoscroll">*/}
                        {/*    <Slider { ...settings }>*/}
                        {/*        /!* Erie *!/*/}
                        {/*        <div>*/}
                        {/*            <img src={ErieCheck} alt="Erie Check" />*/}
                        {/*        </div>*/}
                        {/*        <div>*/}
                        {/*            <img src={LibertyMutualCheck} alt="Liberty Mutual Check" />*/}
                        {/*        </div>*/}
                        {/*        <div>*/}
                        {/*            <img src={NationwideCheck} alt="Nationwide Check" />*/}
                        {/*        </div>*/}
                        {/*        <div>*/}
                        {/*            <img src={StateFarmCheck} alt="State Farm Check" />*/}
                        {/*        </div>*/}
                        {/*        <div>*/}
                        {/*            <img src={TravelersCheck} alt="Travelers Check" />*/}
                        {/*        </div>*/}
                        {/*        <div>*/}
                        {/*            <img src={USAACheck} alt="USAA Check" />*/}
                        {/*        </div>*/}
                        {/*    </Slider>*/}
                        {/*</div>*/}

                        {/*<p className="content-info">By taking advantage of this opportunity, <span className="bold">our customers save $15,855.00 on average</span>, when compared to paying for a new roof*/}
                        {/*    out of their own pocket! I understand this opportunity may sound too good to be true. That's why my inspection is 100% free and without*/}
                        {/*    obligation and I'm happy to answer any questions you may have before scheduling an inspection. I can be reached at (703) 239-3204 by call or text.*/}
                        {/*</p>*/}


                        {/*<p className="content-info">If you're ready to schedule an inspection and would prefer to simply schedule online, please use the tool below to find a*/}
                        {/*    time that's convenient for you. I look forward to assisting you with this project!*/}
                        {/*</p>*/}

                        {/*<div className="defective-shingles-request-container">*/}

                        {/*    <ImageInput ref="firstName" type="firstName" />*/}
                        {/*    <ImageInput ref="lastName" type="lastName" />*/}
                        {/*    <AddressInput parent={this} ref="streetAddress" />*/}
                        {/*    <ImageInput  ref="city" type="city" />*/}
                        {/*    <ImageInput  ref="state" type="state" />*/}
                        {/*    <ImageInput  ref="zipCode" type="zipCode" />*/}
                        {/*    <ImageInput ref="inspectionEmail" type="email" />*/}
                        {/*    <ImageInput ref="inspectionPhone" type="phone" />*/}
                        {/*    <DateTimeInput ref="appointmentTime" program="defective-shingles"/>*/}

                        {/*    <div className="report-submit-button" style={{marginLeft: '0'}} onClick={this.submitClicked} >*/}
                        {/*        <p className="report-submit-label"> Submit Inspection Form </p>*/}
                        {/*        <img className="report-submit-img" src={SUBMIT_ICON} alt="Submit" />*/}
                        {/*    </div>*/}


                    </div>
                <ProgramErrorModal ref="programErrorModal" errors={this.state.errors} />
                <ProgramSuccessModal ref="programSuccessModal"/>
                {/*<GoogleReviews />*/}
            </div>
        )
    }

    addressSelected ( selected ){

        if(selected.city){
            this.refs.city.setState({ value: selected.city })
        }
        if(selected.state){
            this.refs.state.setState({ value: selected.state })
        }
        if(selected.zipCode){
            this.refs.zipCode.setState({ value: selected.zipCode })
        }
    }

    submitClicked = () => {

        const firstName = this.firstNameRef.current.state.value;
        const lastName = this.lastNameRef.current.state.value;
        let streetAddress = this.addressRef.current.state.address;
        const { selectedAddress } = this.state;
        let city = null;
        let state = null;
        let zipCode = null;
        if(selectedAddress){
            if(selectedAddress.streetNumber && selectedAddress.addressLine1){
                streetAddress = `${selectedAddress.streetNumber} ${selectedAddress.addressLine1}`
            }
            if(selectedAddress.city){
                city = selectedAddress.city
            }
            if(selectedAddress.state){
                state = selectedAddress.state
            }
            if(selectedAddress.zipCode){
                zipCode = selectedAddress.zipCode
            }
        }

        const email = this.emailRef.current.state.value;
        const phone = this.phoneRef.current.state.value;
        const appointmentTime = this.dateRef.current.state.selectedTime;


        const repEmail = 'ford.barsi@theroofdocs.com';


        const inspectionBody = {
            firstName: firstName,
            lastName: lastName,
            email: email.replace(/\s/g, ""),
            phoneNumber: phone,
            addressLine1: streetAddress,
            city: city,
            state: state,
            zipCode: zipCode,
            appointmentTime: appointmentTime,
            repEmail: repEmail

        };


        let errors = [];
        if(inspectionBody.firstName.length <= 0){
            errors.push('First Name')
        }
        if(inspectionBody.lastName.length <= 0){
            errors.push('Last Name')
        }
        if(inspectionBody.addressLine1.length <= 0){
            errors.push('Street Address')
        }
        // if(inspectionBody.city.length <= 0){
        //     errors.push('City')
        // }
        // if(inspectionBody.state.length <= 0){
        //     errors.push('State')
        // }
        // if(inspectionBody.zipCode.length <= 0){
        //     errors.push('Zip Code')
        // }
        const emailRegex = /\S+@\S+\.\S+/;
        if(!emailRegex.test(inspectionBody.email.toLowerCase())){
            errors.push("Email Address");
        }

        if(!appointmentTime) {
            errors.push("Appointment Time")
        }



        if(errors.length > 0){

            this.setState({ errors: errors });
            this.refs.programErrorModal.show();

        } else {

            // Clear Text Fields
            this.clearReportFields();

            // Show Modal
            // this.refs.programSuccessModal.show();
            axios.post(`${process.env.REACT_APP_API_URL}inquiry/shingles`, inspectionBody)
                .then(function (response) {

                    // Clear Text Fields
                    // self.clearReportFields();
                    //
                    // // Show Modal
                    // self.refs.programSuccessModal.show();

                    // Set URL
                    window.location.href = "/defective-shingles-program/thankyou"



                }).catch(function (error) {
                    console.log(error);
                });
        }
    };

    clearReportFields(){

        this.firstNameRef.current.setState({ value: '' });
        this.lastNameRef.current.setState({ value: '' });
        this.addressRef.current.setState({ address: '' });
        this.emailRef.current.setState({ value: '' });
        this.phoneRef.current.setState({ value: '' });
        this.dateRef.current.setState({ selectedDate: null, availableTimes: [], selectedTime: null, focused: false });
        this.setState({  selectedAddress: null });


    }
}

export default DefectiveShinglesProgram;

class ProgramErrorModal extends Component {

    constructor(props){
        super(props);

        this.state = { hidden: true, errors: [] };

    }

    show() {
        this.setState({ hidden: false });
    }


    closeModal() {

        this.setState({hidden: true});
    }


    render() {
        return (
            <div className= {"modalContainer" + (this.state.hidden ? ' hiddenModal' : '')} onClick={this.closeModal.bind(this)}>
                <div className="modalContent">
                    <p className="modalHeader">Please correct errors on the following fields and submit again</p>
                    { this.props.errors.map(err => (
                        <p key={err} className="errorItem"> {err} </p>
                    ))}
                    <button className="errorOkButton">Got It!</button>
                </div>
            </div>
        );
    }
}

class ProgramSuccessModal extends Component {

    constructor(props){
        super(props);

        this.state = { hidden: true };

    }

    show() {
        this.setState({ hidden: false });
    }


    closeModal() {

        this.setState({ hidden: true  });
    }

    close = () => {

        this.setState({ hidden: true });
    };


    render() {
        return (
            <div className= {"modalContainer" + (this.state.hidden ? ' hiddenModal' : ' animatedModal')} onClick={this.closeModal.bind(this)}>
                <div className="modalContent">
                    <p className="modalHeader">Thanks for scheduling your free inspection! I look forward to providing you with an honest and straightforward assessment
                        of your property. You should have received an email confirming your appointment. Please contact me at (703) 239-3738 if you need to reschedule or
                        have any questions
                    </p>
                    <button onClick={this.close} className="errorOkButton"> Close </button>
                </div>
            </div>
        );
    }
}