import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import FreeQuote from "../../custom/freeQuoteBanner";

// Styling
import '../../../style/components/mission.scss';
import '../../../style/components/work.scss';

import BANNER_ICON_UP from "../../../resources/images/misc/plus_icon_up.png";

class RoofingFelt extends Component {

    constructor(props) {
        super(props);

        this.state = { };
    }



    render() {

        const search = window.location.search;
        const params = new URLSearchParams(search);
        const ref = params.get('ref') ? params.get('ref') : '0';

        return (
            <div className="blog-template-container">
                <div className="blog-template-banner">
                    <div className="blog-template-text-container">
                        <p className="banner-text-small"> Blog </p>
                        <p className="banner-text-large"> Learn More About Roofing From Roof-ER </p>
                    <Helmet>
                        <title>What You Need to Know About Roofing Felt | Roof-ER</title>
                        <meta name="description" content="Discover what roofing felt is and how it is used to protect your roof from damage. Schedule your free roof inspection with our expert team today!"/>
                    </Helmet>
                    </div>
                    <img className="banner-icon" src={BANNER_ICON_UP} alt="Banner Icon Up" />
                </div>
                <div className="blog-template-content-container">
                    <div className="blog-template-content">
                        <div className="blog-item-container">
                            <div className="blog-item-info-container">
                                <a href="/blog"><button className="blog-home-button"><span className="arrow-left"> Back to Articles </span> </button></a>
                                <div className="blog-item-info-container-controls">
                                    <a href={`/blog?ref=${ref}&dir=prev`}><button  className="blog-previous-button"><span className="arrow-left"> Previous Article </span> </button></a>
                                    <a href={`/blog?ref=${ref}&dir=next`}><button className="blog-next-button"><span className="arrow-right"> Next Article </span> </button></a>
                                </div>
                                <h1><strong>What You Need to Know About Roofing Felt</strong></h1>
                                <p>If you&rsquo;re getting any sort of&nbsp;<a href="/services/roofrepair">roof repair</a>&nbsp;completed on your home or a larger repair job, one of the line items on your invoice will likely be roofing felt. Knowing what felt is can help you determine whether this is something you need.</p>
                                <h2><strong><strong>What Is Roofing Felt?</strong></strong></h2>
                                <p>Also known as tar paper, roofing felt underlayment or roll roofing, roofing felt is a layer between your shingles or roofing material and your home. It offers an extra layer of protection for your roof. It is usually made from specially treated polyester, wood cellulose or fiberglass.</p>
                                <h2><strong><strong>Is Roofing Felt Waterproof?</strong></strong></h2>
                                <p>Roofing felt paper is treated to be waterproof. Usually, it is soaked or coated with asphalt, hence the alternate term "roofing tar paper". This process allows the material to breathe while providing protection from the elements.</p>
                                <h2><strong><strong>Is Roofing Felt Necessary?</strong></strong></h2>
                                <p>Many building codes require tar paper, and the National Roofing Contractors Association (NRCA) recommends it. Beyond that, roll roofing paper is important for several reasons:</p>
                                <ul>
                                    <li>If it snows or rains while roofers are working on your roof, a roll of tar paper will protect your home&rsquo;s roof deck.</li>
                                    <li>Tar paper rolls can allow your roof to look better longer. Roofing paper rolls prevent wood resin stains from your roof deck from getting onto your shingles.</li>
                                    <li>Roofing felt paper roll underlayment offers added protection from the elements, which can help extend the longevity of your roof.</li>
                                    <li>Roll roofing tar paper can help prevent leaks in your home.</li>
                                    <li>The added layer can repel any moisture or rain which seeps in through the shingles during harsh weather conditions.</li>
                                    <li>Roll roofing allows moisture from ice on your roof to melt away from your roof deck.</li>
                                    <li>Roofing felt underlayment buys you some time if you lose some shingles by protecting your home until you can get the shingles replaced.</li>
                                    <li>You may need rolls of roofing felt paper to get a Class A fire rating for your building.</li>
                                    <li>Roofing tar paper can make your roof look better by ensuring the roof deck pattern cannot be visible.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <FreeQuote />
            </div>
        )
    }
}

export default RoofingFelt;